import { z } from 'zod'

import packageInfo from '../../package.json'
import { EnvSchema } from '../../scripts/envValidation/schema'

type EnvTypes = z.infer<typeof EnvSchema>

const envConfig: EnvTypes & { APP_VERSION: string } = {
	VITE_SENTRY_ENV: import.meta.env.VITE_SENTRY_ENV,
	VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
	VITE_FULLCALENDAR_LICENSE_KEY: import.meta.env.VITE_FULLCALENDAR_LICENSE_KEY,
	VITE_CHECK_NETWORK_TIMEOUT_IN_SECONDS: import.meta.env.VITE_CHECK_NETWORK_TIMEOUT_IN_SECONDS,
	VITE_CHECK_NETWORK_URL: import.meta.env.VITE_CHECK_NETWORK_URL,
	VITE_GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
	VITE_GOOGLE_OAUTH_CLIENT_ID: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID,
	VITE_MS_OAUTH_CLIENT_ID: import.meta.env.VITE_MS_OAUTH_CLIENT_ID,
	VITE_NOTINO_GTM_ID: import.meta.env.VITE_NOTINO_GTM_ID,
	APP_VERSION: packageInfo.version
}

export default envConfig
