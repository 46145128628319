import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spin, TableProps } from 'antd'
import { useSelector } from 'react-redux'
import { initialize } from 'redux-form'
import { ColumnsType } from 'antd/es/table'

// components
import CustomTable from '../../components/CustomTable'
import SalonsRejectedSuggestionsFilter from './components/filters/SalonsRejectedSuggestionsFilter'

// utils
import { FORM, ROW_BUTTON_WITH_ID, ROW_GUTTER_X_DEFAULT } from '../../utils/enums'
import { formFieldID, getLinkWithEncodedBackUrl, normalizeDirectionKeys, setOrder } from '../../utils/helper'
import { deleteReq } from '../../utils/request'
import { SalonsPageCommonProps } from './components/salonUtils'

// reducers
import { RootState } from '../../reducers'
import { IRejectedSuggestionsPayload, getRejectedSuggestions } from '../../reducers/salons/salonsActions'

// assets
import IconCheck from '../../assets/icons/checker-icon.svg?react'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { salonsRejectedSuggestionsPageURLQueryParamsSchema } from '../../schemas/queryParams'

// types
import { IRejectedSuggestionsFilter } from '../../types/interfaces'

type Props = SalonsPageCommonProps & {}

type TableDataItem = NonNullable<IRejectedSuggestionsPayload['tableData']>[0]

const SalonsRejectedSuggestionsPage: React.FC<Props> = (props) => {
	const { dispatch, t, navigate } = props

	const [query, setQuery] = useQueryParams(salonsRejectedSuggestionsPageURLQueryParamsSchema, {
		page: 1,
		order: 'userLastName:ASC'
	})

	const salons = useSelector((state: RootState) => state.salons.rejectedSuggestions)
	const [submitting, setSubmitting] = useState(false)

	const loading = salons?.isLoading || submitting

	useEffect(() => {
		dispatch(initialize(FORM.FILTER_REJECTED_SUGGESTIONS, { search: query.search }))
		dispatch(getRejectedSuggestions({ page: query.page, limit: query.limit, order: query.order, search: query.search }))
	}, [dispatch, query.page, query.limit, query.search, query.order])

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}`
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === salons?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	const handleSubmit = (values: IRejectedSuggestionsFilter) => {
		const newQuery = {
			...query,
			...values,
			page: 1
		}
		setQuery(newQuery)
	}

	const markRejectedSalonAsDone = async (salonID: string) => {
		try {
			setSubmitting(true)
			await deleteReq('/api/b2b/admin/salons/{salonID}/rejected-suggestions', { params: { path: { salonID } }, reqBody: {} })
			dispatch(getRejectedSuggestions({ page: query.page, limit: query.limit, order: query.order, search: query.search }))
		} catch (error: any) {
			// eslint-disable-next-line no-console
			console.error(error.message)
		} finally {
			setSubmitting(false)
		}
	}

	const columns: ColumnsType<TableDataItem> = [
		{
			title: t('loc:Salón'),
			className: 'heading-4 text-left',
			children: [
				{
					title: <span id={'sortby-title'}>{t('loc:Názov salónu')}</span>,
					dataIndex: 'salonName',
					key: 'salonName',
					ellipsis: true,
					sorter: true,
					sortDirections: ['ascend', 'descend', 'ascend'],
					sortOrder: setOrder(query.order, 'salonName')
				},
				{
					title: t('loc:Adresa salónu'),
					dataIndex: 'address',
					key: 'address',
					ellipsis: true,
					sorter: false
				},
				{
					title: t('loc:Email salónu'),
					dataIndex: 'salonMail',
					key: 'salonMail',
					ellipsis: true,
					sorter: false
				}
			]
		},
		{
			title: t('loc:Používateľ'),
			className: 'heading-4 text-left',
			children: [
				{
					title: t('loc:Meno a Priezvisko'),
					dataIndex: 'userLastName',
					key: 'userLastName',
					ellipsis: true,
					sorter: true,
					sortDirections: ['ascend', 'descend', 'ascend'],
					sortOrder: setOrder(query.order, 'userLastName')
				},
				{
					title: t('loc:Email používateľa'),
					dataIndex: 'userEmail',
					key: 'userEmail',
					ellipsis: true,
					sorter: true,
					sortDirections: ['ascend', 'descend', 'ascend'],
					sortOrder: setOrder(query.order, 'userEmail')
				},
				{
					title: t('loc:Telefón používateľa'),
					dataIndex: 'userPhone',
					key: 'userPhone',
					ellipsis: true,
					sorter: false
				}
			]
		},
		{
			key: 'ctaButton',
			width: 150,
			children: [
				{
					title: t('loc:Označiť ako vybavené'),
					dataIndex: 'salonID',
					key: 'markAsDone',
					fixed: salons?.tableData?.length ? 'right' : undefined,
					ellipsis: true,
					width: 150,
					render: (_value, record) => (
						<Button
							type={'primary'}
							onClick={(e) => {
								e.stopPropagation()
								markRejectedSalonAsDone(record?.salonID)
							}}
							icon={<IconCheck style={{ width: 12, height: 12 }} />}
							size={'small'}
							disabled={loading}
							className={'noti-btn m-regular w-full hover:shadow-none focus:shadow-none'}
							id={formFieldID(ROW_BUTTON_WITH_ID(record.id))}
						>
							{t('loc:Vybavené')}
						</Button>
					)
				}
			]
		}
	]

	return (
		<Row gutter={ROW_GUTTER_X_DEFAULT}>
			<Col span={24}>
				<div className='content-body'>
					<Spin spinning={loading}>
						<SalonsRejectedSuggestionsFilter onSubmit={handleSubmit} total={salons?.data?.pagination?.totalCount} />
						<CustomTable
							className='table-fixed'
							onChange={onChangeTable}
							columns={columns}
							dataSource={salons?.tableData}
							rowClassName={'clickable-row'}
							rowKey={'id'}
							twoToneRows
							scroll={{ x: 800 }}
							onRow={(record) => ({
								onClick: () => {
									navigate(getLinkWithEncodedBackUrl(t('paths:salons/{{salonID}}', { salonID: record.salonID })))
								}
							})}
							useCustomPagination
							pagination={{
								pageSize: salons?.data?.pagination?.limit,
								total: salons?.data?.pagination?.totalCount,
								current: salons?.data?.pagination?.page,
								disabled: salons?.isLoading,
								onChange: onChangePagination
							}}
						/>
					</Spin>
				</div>
			</Col>
		</Row>
	)
}
export default SalonsRejectedSuggestionsPage
