import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// components
import UserDetail from './components/UserDetail'

// reducers
import { logOutUser, getCurrentUser } from '../../reducers/users/userActions'

// utils
import { PERMISSION } from '../../utils/enums'
import { RootState } from '../../reducers'

const MyProfilePage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const authUser = useSelector((state: RootState) => state.user.authUser)

	const fetchData = useCallback(async () => {
		const { data } = await dispatch(getCurrentUser())

		if (!data?.id) {
			dispatch(logOutUser())
		}
	}, [dispatch])

	useEffect(() => {
		fetchData()
	}, [fetchData])

	return (
		<UserDetail
			userData={authUser.data}
			deleteEntityName={t('loc:účet')}
			onDeleteSuccess={() => dispatch(logOutUser())}
			ignoreDeletePermissions
			submitPermissions={[PERMISSION.NOTINO, PERMISSION.PARTNER, PERMISSION.NOTINO_SUPER_ADMIN, PERMISSION.NOTINO_SUPER_ADMIN]}
			onPatchSuccess={fetchData}
			isLoadingUser={authUser.isLoading}
		/>
	)
}

export default MyProfilePage
