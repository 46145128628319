import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Row, Spin } from 'antd'
import colors from 'tailwindcss/colors'
import i18next from 'i18next'
import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router'
import { ColumnsType } from 'antd/es/table'

// types
import { AlertData, DashboardData, ILoadingAndFailure, TimeStats, TimeStatsData } from '../../../types/interfaces'

// components
import Statistics from '../../../components/Dashboards/Statistics'
import EmptySalons from '../../../components/EmptySalons'

// utils
import { SALON_FILTER_STATES, SALON_STATS_ANNUAL_ID, SALON_STATS_MONTHLY_ID, SALONS_TIME_STATS_TYPE } from '../../../utils/enums'
import { getFilterRanges, LineContentFunction, TimeStatsFilterFunction, transformToStatsData } from '../dashboardUtils'
import { getFilterPaths } from '../../../utils/helper'

// redux
import { INotinoDashboard, INotinoDashboardPayload, ISalonsTimeStatsPayload, getSalonsAnnualStats, getSalonsMonthStats } from '../../../reducers/dashboard/dashboardActions'

// assets
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg?react'

// dynamic imports
const DoughnutChart = React.lazy(() => import('./DoughnutChart'))

const doughnutContent = (label: string, source?: any[], onlyLegend?: boolean) => {
	return (
		<div className='statistics-box py-4 px-6 md:py-8 md:px-12 statistics-box-wide'>
			<div className='flex flex-wrap justify-between w-full'>
				<h4>{label}</h4>
			</div>
			{source && (
				<div className='flex flex-wrap justify-between w-full mt-4'>
					{!onlyLegend && (
						<div className='graph-doughnut-wrapper w-2/5 3xl:w-12/25 flex items-center'>
							<DoughnutChart source={source} />
						</div>
					)}
					<div className='flex flex-1 items-center'>
						<div className='w-full flex flex-col gap-4'>
							{source.map((item: any, index: number) => (
								// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
								<div key={index} className='flex items-center w-full h-6 cursor-pointer' onClick={item.onClick}>
									<div className={'flex flex-1 items-center legend-row'}>
										<div className='h-6 w-6 rounded-full mr-4 stats-circle' style={{ backgroundColor: item.background }} />
										<span className='base-semibold mr-3 leading-4'>{item.data}</span>
										<span className='text-sm'>{item.label}</span>
									</div>
									<ChevronDownIcon style={{ transform: 'rotate(-90deg)' }} />
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}

const salonColumns = (labels: string[] = [], futureBreak = 0): ColumnsType<TimeStatsData['columns'][0]> => {
	return [
		{
			key: 'type',
			dataIndex: 'type',
			render: (value) => {
				switch (value) {
					case SALONS_TIME_STATS_TYPE.BASIC:
						return (
							<div className={'flex flex-1 items-center'}>
								<div className='h-2-5 w-2-5 rounded-full mr-1 stats-circle' style={{ backgroundColor: colors.blue[200], flex: '0 0 auto' }} />
								<span className='xs-bold'>{i18next.t('loc:BASIC')}</span>
							</div>
						)
					case SALONS_TIME_STATS_TYPE.PREMIUM:
						return (
							<div className={'flex flex-1 items-center'}>
								<div className='h-2-5 w-2-5 rounded-full mr-1 stats-circle' style={{ backgroundColor: colors.blue[700], flex: '0 0 auto' }} />
								<span className='xs-bold'>{i18next.t('loc:Schválené PREMIUM')}</span>
							</div>
						)
					case SALONS_TIME_STATS_TYPE.PENDING:
					default:
						return (
							<div className={'flex flex-1 items-center'}>
								<div className='h-2-5 w-2-5 rounded-full mr-1 stats-circle' style={{ backgroundColor: colors.yellow[400], flex: '0 0 auto' }} />
								<span className='xs-bold'>{i18next.t('loc:Žiadosti o PREMIUM')}</span>
							</div>
						)
				}
			}
		},
		...labels.map((label: string, index: number) => {
			return {
				key: index,
				dataIndex: index,
				className: cx({ 'future-divider': futureBreak - 0.5 === index }), // 0.5 is delta for display devider between columns
				title: <span className={cx('xs-semibold', { 'text-notino-gray': futureBreak <= index })}>{label}</span>,
				render: (value: number) => <span className={cx('xs-regular', { 'text-notino-gray': futureBreak <= index })}>{value}</span>
			}
		}),
		{
			key: 'summary',
			dataIndex: 'summary',
			title: () => <span className='xs-semibold'>{i18next.t('loc:Súčet')}</span>,
			render: (value) => <span className='xs-regular'>{value}</span>,
			align: 'center'
		}
	]
}

type Props = {
	lineContent: LineContentFunction
	timeStatsFilter: TimeStatsFilterFunction
	notinoData: INotinoDashboardPayload & ILoadingAndFailure
	salonsMonthStats: ISalonsTimeStatsPayload & ILoadingAndFailure
	salonsAnnualStats: ISalonsTimeStatsPayload & ILoadingAndFailure
	selectedCountry?: string
}

const SalonsDashboardTab = (props: Props) => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const { timeStatsFilter, lineContent, salonsAnnualStats, salonsMonthStats, notinoData, selectedCountry } = props
	const now = dayjs()
	const [annualStatsDate, setAnnualStatsDate] = useState<Dayjs>(now)
	const [monthStatsDate, setMonthStatsDate] = useState<Dayjs>(now)

	const navigate = useNavigate()

	useEffect(() => {
		// months are indexed from 0 and API has indexed months from 1
		dispatch(getSalonsMonthStats(monthStatsDate.year(), monthStatsDate.month() + 1, selectedCountry))
		dispatch(getSalonsAnnualStats(annualStatsDate.year(), selectedCountry))
	}, [dispatch, annualStatsDate, monthStatsDate, selectedCountry])

	const annualStats: TimeStats = useMemo(() => {
		return transformToStatsData(salonsAnnualStats.data, salonsAnnualStats.isLoading, salonsAnnualStats.isFailure, annualStatsDate)
	}, [salonsAnnualStats, annualStatsDate])

	const monthStats: TimeStats = useMemo(() => {
		return transformToStatsData(salonsMonthStats.data, salonsMonthStats.isLoading, salonsMonthStats.isFailure, monthStatsDate)
	}, [salonsMonthStats, monthStatsDate])

	const dashboardData: DashboardData = useMemo(() => {
		const emptyGraphData = {
			premiumVsBasic: [],
			salonStates: [],
			noSalons: true
		}

		if (notinoData.data) {
			const ranges = getFilterRanges()

			const alertData: AlertData[] = [
				{
					label: <>{t('loc:Salóny čakajúce na schválenie')}</>,
					count: notinoData.data.pendingSalons,
					onClick: () => navigate(getFilterPaths(selectedCountry).SALONS[SALON_FILTER_STATES.PENDING_PUBLICATION])
				},
				{
					label: <>{t('loc:BASIC salóny, systémom omylom navrhnuté na spárovanie')}</>,
					count: notinoData.data.suggestionIncidents,
					onClick: () => navigate(getFilterPaths().SALONS.rejectedSuggestions)
				},
				{
					label: (
						<span>
							{`${t('loc:Zmeny v salónoch za')} `}
							<strong>{t('loc:24 hodín')}</strong>
						</span>
					),
					count: notinoData.data.lastUpdated?.oneDayAgo,
					onClick: () => navigate(getFilterPaths(selectedCountry, ranges[0].from, ranges[0].to).SALONS.changesOverPeriod) // 24h ago
				},
				{
					label: (
						<span>
							{`${t('loc:Zmeny v salónoch za')} `}
							<strong>{t('loc:48 hodín')}</strong>
						</span>
					),
					count: notinoData.data.lastUpdated?.twoDaysAgo,
					onClick: () => navigate(getFilterPaths(selectedCountry, ranges[1].from, ranges[1].to).SALONS.changesOverPeriod) // 48h ago
				},
				{
					label: (
						<span>
							{`${t('loc:Zmeny v salónoch za')} `}
							<strong>{t('loc:týždeň')}</strong>
						</span>
					),
					count: notinoData.data.lastUpdated?.sevenDaysAgo,
					onClick: () => navigate(getFilterPaths(selectedCountry, ranges[2].from, ranges[2].to).SALONS.changesOverPeriod) // week ago
				}
			]

			const graphProperties = ['basicSalons', 'nonBasicSalons', 'declinedSalons', 'pendingSalons', 'unpublishedSalons']
			type GraphProps = Pick<INotinoDashboard, 'basicSalons' | 'nonBasicSalons' | 'declinedSalons' | 'pendingSalons' | 'unpublishedSalons'>
			let sum = 0

			graphProperties.forEach((property: string) => {
				sum += notinoData.data ? notinoData.data[property as keyof GraphProps] : 0
			})
			if (sum === 0) {
				return {
					alertData,
					graphData: emptyGraphData
				}
			}

			// colors are defined in tailwind.config -> colors -> status
			const graphData = {
				premiumVsBasic: [
					{
						data: notinoData.data.basicSalons,
						background: colors.blue[200],
						onClick: () => navigate(getFilterPaths(selectedCountry).SALONS.publishedBasics),
						label: t('loc:BASIC salóny')
					},
					{
						data: notinoData.data.nonBasicSalons,
						background: colors.blue[700],
						onClick: () => navigate(getFilterPaths(selectedCountry).SALONS.publishedPremiums),
						label: t('loc:PREMIUM salóny')
					}
				],
				salonStates: [
					{
						data: notinoData.data.declinedSalons,
						background: colors.red[200],
						onClick: () => navigate(getFilterPaths(selectedCountry).SALONS[SALON_FILTER_STATES.DECLINED]),
						label: t('loc:Zamietnuté')
					},
					{
						data: notinoData.data.pendingSalons,
						background: colors.yellow[400],
						onClick: () => navigate(getFilterPaths(selectedCountry).SALONS[SALON_FILTER_STATES.PENDING_PUBLICATION]),
						label: t('loc:Na schválenie')
					},
					{
						data: notinoData.data.unpublishedSalons,
						background: colors.neutral[200],
						onClick: () => navigate(getFilterPaths(selectedCountry).SALONS[SALON_FILTER_STATES.NOT_PUBLISHED]),
						label: t('loc:Nepublikované')
					},
					{
						data: notinoData.data.publishedSalons,
						background: colors.green[200],
						onClick: () => navigate(getFilterPaths(selectedCountry).SALONS[SALON_FILTER_STATES.PUBLISHED]),
						label: t('loc:Všetky publikované')
					}
				]
			}

			return {
				alertData,
				graphData
			}
		}

		return {
			alertData: [],
			graphData: emptyGraphData
		} as DashboardData
	}, [notinoData, t, navigate, selectedCountry])

	return (
		<>
			<Spin spinning={notinoData?.isLoading}>
				<div className='dashboard-grid'>
					{dashboardData.alertData.map((item: AlertData, index: number) => {
						return <Statistics key={`statistics_item_${index}`} title={item.label} count={item.count} onActionItemClick={item.onClick} />
					})}
				</div>

				{dashboardData.graphData.noSalons ? (
					<EmptySalons />
				) : (
					<>
						{/* dougnut graphs */}
						<Row className='mt-12 gap-4'>
							{doughnutContent(t('loc:Publikované salóny - Premium vs. Basic'), dashboardData.graphData.premiumVsBasic)}
							{doughnutContent(t('loc:Stav salónov'), dashboardData.graphData.salonStates, true)}
						</Row>
						{/* line graphs */}
						{lineContent(
							t('loc:Vývoj salónov - mesačný'),
							monthStats,
							timeStatsFilter(
								(date) => {
									if (date) {
										setMonthStatsDate(date)
										dispatch(getSalonsMonthStats(Number(date.year()), Number(date.month() + 1)))
									}
								},
								'MMMM - YYYY',
								SALON_STATS_MONTHLY_ID,
								monthStats.isLoading
							),
							salonColumns(monthStats.data?.labels, monthStats.data?.breakIndex)
						)}
						{lineContent(
							t('loc:Vývoj salónov - ročný'),
							annualStats,
							timeStatsFilter(
								(date, dateString) => {
									if (date) {
										setAnnualStatsDate(date)
									}
									dispatch(getSalonsAnnualStats(Number(dateString)))
								},
								undefined,
								SALON_STATS_ANNUAL_ID,
								annualStats.isFailure
							),
							salonColumns(annualStats.data?.labels, annualStats.data?.breakIndex)
						)}
					</>
				)}
			</Spin>
		</>
	)
}

export default SalonsDashboardTab
