// types
import { CUSTOMER, CUSTOMERS } from './customerTypes'
import { IResetStore } from '../generalTypes'
// eslint-disable-next-line import/no-cycle
import { ThunkResult } from '../index'
import { IGetCustomersQueryParams } from '../../types/schemaTypes'
import { GetUrls, ISearchable, ISelectOptionItem, RequestResponse } from '../../types/interfaces'

// utils
import { getReq } from '../../utils/request'
import { getCustomerName } from '../../utils/helper'

export type ICustomerActions = IResetStore | IGetCustomers | IGetCustomer

interface IGetCustomers {
	type: CUSTOMERS
	payload: ICustomersPayload
}

interface IGetCustomer {
	type: CUSTOMER
	payload: ICustomerPayload
}

export interface ICustomerPayload {
	data: RequestResponse<GetUrls['/api/b2b/admin/customers/{customerID}']> | null
}

export interface ICustomersPayload extends ISearchable<RequestResponse<GetUrls['/api/b2b/admin/customers/']>> {}

export const getCustomers =
	(queryParams: IGetCustomersQueryParams): ThunkResult<Promise<ICustomersPayload>> =>
	async (dispatch) => {
		let payload = {} as ICustomersPayload

		try {
			dispatch({ type: CUSTOMERS.CUSTOMERS_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/customers/', {
				params: {
					query: {
						page: queryParams.page,
						limit: queryParams.limit,
						order: queryParams.order,
						salonID: queryParams.salonID || undefined,
						search: queryParams.search || undefined
					}
				},
				reqBody: {}
			})

			const options: ISelectOptionItem[] = data?.customers.map((customer) => ({
				key: customer.id,
				label: getCustomerName(customer),
				value: customer.id
			}))

			payload = {
				data,
				options
			}

			dispatch({ type: CUSTOMERS.CUSTOMERS_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: CUSTOMERS.CUSTOMERS_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}
		return payload
	}

export const getCustomer =
	(customerID: string): ThunkResult<Promise<ICustomerPayload>> =>
	async (dispatch) => {
		let payload = {} as ICustomerPayload
		try {
			dispatch({ type: CUSTOMER.CUSTOMER_LOAD_START })

			const { data } = await getReq('/api/b2b/admin/customers/{customerID}', { params: { path: { customerID } }, reqBody: {} })

			payload = {
				data
			}

			dispatch({ type: CUSTOMER.CUSTOMER_LOAD_DONE, payload })
		} catch (err) {
			dispatch({ type: CUSTOMER.CUSTOMER_LOAD_FAIL })
			// eslint-disable-next-line no-console
			console.error(err)
		}

		return payload
	}
