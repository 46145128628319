/* eslint-disable prefer-rest-params */
import { isNil, omitBy } from 'lodash'
import dayjs from 'dayjs'
import { EmptyObject, Store } from 'redux'
import {
	IEmployeeForm,
	IEmployeeServicesForm,
	IEmployeeShiftPlanningForm,
	IEmployeeShiftsForm,
	IReservationSystemSettingsForm,
	IReservationsSettingsDisabledNotification,
	IServiceCustomForm,
	IServiceForm
} from '../types/interfaces'
import { RootState } from '../reducers'

import { checkPermissions } from './Permissions'
import { DATA_LAYER_EVENTS, GA_CONTEXT_MENU_TYPE, GA_FILTER_TYPE, GA_SCREEN_CLASS, GA_SCREEN_NAME, GA_RESERVATION_STATUS, GA_RESERVATION_TIME } from './dataLayerEnums'
import {
	BILLING_INFO_FORM_SECTION,
	PERMISSION,
	RESERVATION_STATE,
	RS_FORWARDING_ROLES,
	RS_NOTIFICATION_CHANNEL,
	RS_NOTIFICATION_CUSTOMER,
	RS_NOTIFICATION_EMPLOYEE,
	SALON_DETAIL_FORM_SECTION
} from './enums'
import { IServiceColorsPayload } from '../reducers/services/serviceActions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function gtag(...args: any[]): void {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(arguments)
}

type GaScreenViewEvent = {
	event: DATA_LAYER_EVENTS.SCREEN_NAME
	screen_name: GA_SCREEN_NAME
	screen_class: GA_SCREEN_CLASS
}

type GaScreenDurationEvent = {
	event: DATA_LAYER_EVENTS.SCREEN_DURATION
	screen_name: GA_SCREEN_NAME
	screen_time: number
}

type GaClickButtonEvent = {
	event: DATA_LAYER_EVENTS.CLICK_BUTTON
	button_name: string
	screen_name?: GA_SCREEN_NAME
}

type GaToggleSwitchEvent = {
	event: DATA_LAYER_EVENTS.TOGGLE_SWITCH
	checkbox_name: string
	screen_name: GA_SCREEN_NAME
	checked_state: boolean
}

type GaToggleCheckboxEvent = {
	event: DATA_LAYER_EVENTS.TOGGLE_CHECKBOX
	checkbox_name: string
	screen_name: GA_SCREEN_NAME
	checked_state: boolean
}

type GaOpenContextMenuEvent = {
	event: DATA_LAYER_EVENTS.OPEN_CONTEXT_MENU
	context_menu_type: GA_CONTEXT_MENU_TYPE
	screen_name: GA_SCREEN_NAME
}

type GaSelectContextMenuOptionEvent = {
	event: DATA_LAYER_EVENTS.SELECT_CONTEXT_MENU_OPTION
	context_menu_type: GA_CONTEXT_MENU_TYPE
	screen_name: GA_SCREEN_NAME
	selected_option: string
}

type GaSelectSegmentEvent = {
	event: DATA_LAYER_EVENTS.SELECT_SEGMENT
	screen_name: GA_SCREEN_NAME
	segment_name: string
	selected_segment: string
}

type GaOpenDropdownFilterEvent = {
	event: DATA_LAYER_EVENTS.OPEN_DROPDOWN_FILTER
	filter_type: GA_FILTER_TYPE
	screen_name: GA_SCREEN_NAME
}

type GaSelectDropdownFilterEvent = {
	event: DATA_LAYER_EVENTS.SELECT_DROPDOWN_FILTER_OPTION
	filter_type: GA_FILTER_TYPE
	screen_name: GA_SCREEN_NAME
	selected_option: string
}

type GaSearchEvent = {
	event: DATA_LAYER_EVENTS.SEARCH
	screen_name: GA_SCREEN_NAME
}

type GaOpenReservationDetailEvent = {
	event: DATA_LAYER_EVENTS.OPEN_RESERVATION_DETAIL
	screen_name: GA_SCREEN_NAME
	reservation_status?: GA_RESERVATION_STATUS
	reservation_time: GA_RESERVATION_TIME
}

type GaChangeRadioButtonValueEvent = {
	event: DATA_LAYER_EVENTS.CHANGE_RADIO_BUTTON_VALUE
	screen_name: GA_SCREEN_NAME
	selected_value: string
}

type GaDragEvent = {
	event: DATA_LAYER_EVENTS.DRAG
	screen_name: GA_SCREEN_NAME
	component: string
	position_changed: boolean
	delta_change: number
}

type GaResizeEvent = {
	event: DATA_LAYER_EVENTS.RESIZE
	screen_name: GA_SCREEN_NAME
	component: string
	length_changed: boolean
	delta_change: number
	side?: 'start' | 'end'
}

type GaEvent<AdditionalData extends Object = {}> = { _clear?: boolean } & AdditionalData &
	(
		| GaScreenViewEvent
		| GaScreenDurationEvent
		| GaClickButtonEvent
		| GaToggleSwitchEvent
		| GaToggleCheckboxEvent
		| GaOpenContextMenuEvent
		| GaSelectContextMenuOptionEvent
		| GaSelectSegmentEvent
		| GaOpenDropdownFilterEvent
		| GaSelectDropdownFilterEvent
		| GaSearchEvent
		| GaOpenReservationDetailEvent
		| GaOpenContextMenuEvent
		| GaSelectContextMenuOptionEvent
		| GaChangeRadioButtonValueEvent
		| GaDragEvent
		| GaResizeEvent
	)

let appStore: Store<EmptyObject & RootState>

export const initAppStoreForGa = (store: Store<EmptyObject & RootState>) => {
	appStore = store
}

export const pushEventToDataLayer = <AdditionalData extends Object = {}>(data: GaEvent<AdditionalData>) => {
	// eslint-disable-next-line no-underscore-dangle
	const _clear = data._clear !== false

	let user_role: string | undefined

	if (!appStore) {
		// eslint-disable-next-line no-console
		console.warn('Redux AppStore is not yet initialized!')
	}

	const userPermissions = appStore?.getState().user.authUser.data?.uniqPermissions

	if (userPermissions) {
		user_role = checkPermissions(userPermissions, [PERMISSION.PARTNER], [PERMISSION.NOTINO, PERMISSION.NOTINO_ADMIN, PERMISSION.NOTINO_SUPER_ADMIN]) ? 'partner' : 'notino'
	}

	let dataToPush = { ...data, user_role }

	if (_clear) {
		dataToPush = {
			...dataToPush,
			_clear: true
		}
	}

	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(dataToPush)
}

export type SalonDetailSectionChanges = 'salon_basic_information' | 'contact_information' | 'address' | 'opening_hours' | 'payment_options' | 'social_networks'

export const SALON_DETAIL_CHANGES: Record<SALON_DETAIL_FORM_SECTION, SalonDetailSectionChanges> = {
	[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION]: 'salon_basic_information',
	[SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION]: 'contact_information',
	[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]: 'address',
	[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]: 'opening_hours',
	[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION]: 'payment_options',
	[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION]: 'social_networks'
}

export type BillingInfoFormChanges = 'contact_person' | 'bank_details' | 'corporate_information' | 'billing_address'

export const BILLING_INFO_CHANGES: Record<BILLING_INFO_FORM_SECTION, BillingInfoFormChanges> = {
	[BILLING_INFO_FORM_SECTION.CONTACT_PERSON_SECTION]: 'contact_person',
	[BILLING_INFO_FORM_SECTION.BANK_DETAILS_SECTION]: 'bank_details',
	[BILLING_INFO_FORM_SECTION.CORPORATE_INFORMATION_SECTION]: 'corporate_information',
	[BILLING_INFO_FORM_SECTION.BILLING_ADDRESS_SECTION]: 'billing_address'
}

export const getGaFormWithSectionsChanges = <Section extends string, Values extends Record<Section, any> | undefined, Changes>(
	changes: Record<Section, Changes>,
	initial: Values,
	values: Values
) => {
	const dirtyFields: Changes[] = []

	if (!initial || !values) {
		return dirtyFields
	}

	Object.entries(changes).forEach(([sectionName, changesKey]) => {
		const typedSectionName = sectionName as Section
		const initialValuesSection = initial[typedSectionName]
		const currentValuesSection = values[typedSectionName]

		const values1 = omitBy(initialValuesSection, isNil)
		const values2 = omitBy(currentValuesSection, isNil)

		if (JSON.stringify(values1) !== JSON.stringify(values2)) {
			dirtyFields.push(changesKey as Changes)
		}
	})

	return dirtyFields
}

export type ReservationSettingsFormChanges = {
	changes_time_limits: number
	changes_client_notifications: number
	changes_internal_notification: number
}

const countNotificationsChangedValues = <NotificationEntity extends string = string>(
	data1: Record<NotificationEntity, IReservationsSettingsDisabledNotification>,
	data2: Record<NotificationEntity, IReservationsSettingsDisabledNotification>
): number => {
	let count = 0

	if (!data1 || !data2) {
		return count
	}

	Object.entries<IReservationsSettingsDisabledNotification>(data1).forEach(([keyType, channels]) => {
		channels?.forEach((channel, channelIndex) => {
			Object.entries(channel).forEach(([channelType, value]) => {
				const data2Channels = data2[keyType as NotificationEntity]
				if (data2Channels) {
					const data2channel = data2Channels[channelIndex]?.[channelType as RS_NOTIFICATION_CHANNEL]
					if (value.value !== data2channel?.value) {
						count += 1
					}
				}
			})
		})
	})

	return count
}

const countEmployeeForwardingNotificationsChangedValues = (
	data1: IReservationSystemSettingsForm['forwardingNotifications']['employee'],
	data2: IReservationSystemSettingsForm['forwardingNotifications']['employee']
): number => {
	let count = 0

	if (!data1 || !data2) {
		return count
	}

	data1.forEach((channel, channelIndex) => {
		Object.entries(channel).forEach(([channelType, value]) => {
			const channelTypeTyped = channelType as RS_FORWARDING_ROLES
			const data2channel = data2[channelIndex]?.[channelTypeTyped]
			if (value.value !== data2channel?.value) {
				count += 1
			}
		})
	})

	return count
}

const countTimeLimitsChangedValues = <T extends keyof IReservationSystemSettingsForm['timeLimits']>(
	data1: IReservationSystemSettingsForm['timeLimits'],
	data2: IReservationSystemSettingsForm['timeLimits']
): number => {
	let count = 0

	if (!data1 || !data2) {
		return count
	}

	;(Object.keys(data1) as T[]).forEach((key) => {
		if (data2[key] !== data1[key]) {
			count += 1
		}
	})

	return count
}

export const getGaReservationSettingsFormChanges = (
	initial: IReservationSystemSettingsForm | undefined,
	values: IReservationSystemSettingsForm | undefined
): ReservationSettingsFormChanges => {
	let changes = {
		changes_time_limits: 0,
		changes_client_notifications: 0,
		changes_internal_notification: 0
	}

	if (!initial || !values) {
		return changes
	}

	changes = {
		...changes,
		changes_time_limits: countTimeLimitsChangedValues(initial.timeLimits, values.timeLimits),
		changes_client_notifications: countNotificationsChangedValues<RS_NOTIFICATION_CUSTOMER>(initial.disabledNotifications.customer, values.disabledNotifications.customer),
		changes_internal_notification:
			countNotificationsChangedValues<RS_NOTIFICATION_EMPLOYEE>(initial.disabledNotifications.employee, values.disabledNotifications.employee) +
			countEmployeeForwardingNotificationsChangedValues(initial.forwardingNotifications.employee, values.forwardingNotifications.employee)
	}

	return changes
}

export const getGaReservationStatus = (reservationState: RESERVATION_STATE): GA_RESERVATION_STATUS => {
	switch (reservationState) {
		case RESERVATION_STATE.APPROVED:
			return GA_RESERVATION_STATUS.APPROVED
		case RESERVATION_STATE.REALIZED:
			return GA_RESERVATION_STATUS.REALIZED
		case RESERVATION_STATE.NOT_REALIZED:
			return GA_RESERVATION_STATUS.NOT_REALIZED
		case RESERVATION_STATE.PENDING:
			return GA_RESERVATION_STATUS.PENDING
		case RESERVATION_STATE.DECLINED:
			return GA_RESERVATION_STATUS.DECLINED
		case RESERVATION_STATE.CANCEL_BY_CUSTOMER:
		case RESERVATION_STATE.CANCEL_BY_SALON:
			return GA_RESERVATION_STATUS.CANCELLED
		default:
			throw new Error('New reservation state added. Please provide GA_RESERVATION_STATUS value corresponding to it')
	}
}

export const getGaReservationTime = (startDateTime: string, endDateTime: string): GA_RESERVATION_TIME => {
	const now = dayjs()
	if (dayjs(startDateTime).isSameOrBefore(now) && dayjs(endDateTime).isSameOrAfter(now)) {
		return GA_RESERVATION_TIME.PENDING
	}

	if (dayjs(startDateTime).isAfter(now)) {
		return GA_RESERVATION_TIME.FUTURE
	}
	return GA_RESERVATION_TIME.PAST
}

export type EmployeePersonalDataFormChanges = {
	changed_name: boolean
	changed_surname: boolean
	changed_mail: boolean
	changed_phone_number: boolean
	changed_prefix: boolean
}

export const getGaEmployeePersonalDataFormChanges = (initial: Partial<IEmployeeForm> | undefined, values: Partial<IEmployeeForm> | undefined): EmployeePersonalDataFormChanges => {
	return {
		changed_name: initial?.firstName !== values?.firstName,
		changed_surname: initial?.lastName !== values?.lastName,
		changed_mail: initial?.email !== values?.email,
		changed_phone_number: initial?.phone !== values?.phone,
		changed_prefix: initial?.phonePrefixCountryCode !== values?.phonePrefixCountryCode
	}
}

export type EmployeeShiftsFormChanges = {
	changed_working_days: boolean
	changed_working_hours_range: boolean
	changed_according_to_salon: boolean
}

const getWorkingHoursCount = (workingHours: IEmployeeShiftsForm['workingHours']) => workingHours.reduce((acc, cv) => (cv.checked ? acc + 1 : acc), 0)
const getWorkingHoursRangesCount = (workingHours: IEmployeeShiftsForm['workingHours']) =>
	workingHours.reduce((acc, cv) => {
		return cv.checked ? acc + cv.timeRanges.filter((timeRange) => timeRange.timeFrom && timeRange.timeTo).length : acc
	}, 0)

export const getGaEmployeeShiftsFormChanges = (
	initial: Partial<IEmployeeShiftsForm> | undefined,
	values: Partial<IEmployeeShiftsForm> | undefined,
	areWorkingHoursSetAccordingToSalonOpeningHours: boolean
): EmployeeShiftsFormChanges => {
	return {
		changed_working_days: getWorkingHoursCount(initial?.workingHours || []) !== getWorkingHoursCount(values?.workingHours || []),
		changed_working_hours_range: getWorkingHoursRangesCount(initial?.workingHours || []) !== getWorkingHoursRangesCount(values?.workingHours || []),
		changed_according_to_salon: areWorkingHoursSetAccordingToSalonOpeningHours
	}
}

export type EmployeeShiftPlanningFormChanges = {
	changed_selected_date: boolean
	changed_start_time: boolean
	changed_end_time: boolean
}

export const getGaEmployeeShiftPlanningFormChanges = (
	initial: Partial<IEmployeeShiftPlanningForm> | undefined,
	values: Partial<IEmployeeShiftPlanningForm> | undefined
): EmployeeShiftPlanningFormChanges => {
	return {
		changed_selected_date: initial?.date !== values?.date,
		changed_start_time: initial?.timeFrom !== values?.timeFrom,
		changed_end_time: initial?.timeTo !== values?.timeTo
	}
}

export type EmployeeServicesFormChanges = {
	changed_assigned_services: boolean
}

export const getGaEmployeeServicesFormChanges = (
	initial: Partial<IEmployeeServicesForm> | undefined,
	values: Partial<IEmployeeServicesForm> | undefined
): EmployeeServicesFormChanges => {
	const services1 = initial?.services || []
	const services2 = values?.services || []

	let areServicesChanged = false

	if (services1.length !== services2.length) {
		areServicesChanged = true
	} else {
		areServicesChanged = !services1.every((service1) => services2.find((service2) => service2.id === service1.id))
	}

	return {
		changed_assigned_services: areServicesChanged
	}
}

export type ServiceFormChanges = {
	changed_custom_description_native: boolean
	description_length_native: number
	description_length_difference_native: number
	changed_custom_description_foreign: boolean
	description_length_foreign: number
	description_length_difference_foreign: number
	changed_colleagues: boolean
	changed_online_reservation: boolean
	changed_auto_confirmation: boolean
	changed_service_color: boolean
}

export const getGaServiceFormChanges = (initial: Partial<IServiceForm> | undefined, values: Partial<IServiceForm> | undefined): ServiceFormChanges => {
	let changed_custom_description_native = false
	let description_length_native = 0
	let description_length_difference_native = 0
	let changed_custom_description_foreign = false
	let description_length_foreign = 0
	let description_length_difference_foreign = 0

	if (values?.descriptionLocalizations?.use) {
		// Switch na custom popis je pri ulozeni on
		// true, ak sa zmenil ten popis oproti povodnemu, inak false
		changed_custom_description_native = !initial?.descriptionLocalizations?.use
			? true
			: values.descriptionLocalizations.defaultLanguage !== initial?.descriptionLocalizations?.defaultLanguage
		changed_custom_description_foreign = !initial?.descriptionLocalizations?.use
			? true
			: values.descriptionLocalizations.enLanguage !== initial?.descriptionLocalizations?.enLanguage
		// dlzka popisu v momente ulozenia
		description_length_native = values.descriptionLocalizations.defaultLanguage?.length || 0
		description_length_foreign = values.descriptionLocalizations.enLanguage?.length || 0
		// rozdiel medzi dlzkou popisu v momente ulozenia a povodnou dlzkou (+/-, 0 ak ziadna)
		// ak bol na zaciatku switch vypnuty, tak berieme pociatocnu hodnotu ako 0
		const initialDescriptionNativeLength = !initial?.descriptionLocalizations?.use ? 0 : initial?.descriptionLocalizations?.defaultLanguage?.length || 0
		const initialDescriptionForeignLength = !initial?.descriptionLocalizations?.use ? 0 : initial?.descriptionLocalizations?.enLanguage?.length || 0
		description_length_difference_native = description_length_native - initialDescriptionNativeLength
		description_length_difference_foreign = description_length_foreign - initialDescriptionForeignLength
	} else {
		// Switch na custom popis je pri ulozeni off
		// v pripade, ze predtym bol switch na custom popis on, inak false
		changed_custom_description_native = !!initial?.descriptionLocalizations?.use
		changed_custom_description_foreign = !!initial?.descriptionLocalizations?.use
		// vzdy bude 0, pretoze switch je vypnuty
		description_length_native = 0
		description_length_foreign = 0
		// ak bol predtym switch na custom popis on, tak sa vyrata delta (0 novych - napr. 356 povodnych znakov = -365), ak bol predtym off, tak je to 0 (0 novych - 0 povodnych)
		description_length_difference_native = initial?.descriptionLocalizations?.use
			? description_length_native - (initial?.descriptionLocalizations?.defaultLanguage?.length || 0)
			: 0
		description_length_difference_foreign = initial?.descriptionLocalizations?.use
			? description_length_foreign - (initial?.descriptionLocalizations?.enLanguage?.length || 0)
			: 0
	}

	return {
		changed_custom_description_native,
		description_length_native,
		description_length_difference_native,
		changed_custom_description_foreign,
		description_length_foreign,
		description_length_difference_foreign,
		changed_colleagues: initial?.assignedEmployees?.length !== values?.assignedEmployees?.length,
		changed_online_reservation: initial?.settings?.enabledB2cReservations !== values?.settings?.enabledB2cReservations,
		changed_auto_confirmation: initial?.settings?.autoApproveReservations !== values?.settings?.autoApproveReservations,
		changed_service_color: initial?.color !== values?.color
	}
}

export type EditServiceCustomFormChanges = {
	changed_custom_service_name: boolean
	description_length: number
	description_length_difference: number
	changed_colleagues: boolean
	changed_online_reservation: boolean
	changed_auto_confirmation: boolean
	changed_service_color: boolean
}

export const getGaEditServiceCustomFormChanges = (
	initial: Partial<IServiceCustomForm> | undefined,
	values: Partial<IServiceCustomForm> | undefined
): EditServiceCustomFormChanges => {
	return {
		changed_custom_service_name: initial?.serviceName !== values?.serviceName,
		description_length: values?.descriptionLocalizations?.defaultLanguage?.length || 0,
		description_length_difference: (initial?.descriptionLocalizations?.defaultLanguage?.length || 0) - (values?.descriptionLocalizations?.defaultLanguage?.length || 0),
		changed_colleagues: initial?.assignedEmployees?.length !== values?.assignedEmployees?.length,
		changed_online_reservation: initial?.settings?.enabledB2cReservations !== values?.settings?.enabledB2cReservations,
		changed_auto_confirmation: initial?.settings?.autoApproveReservations !== values?.settings?.autoApproveReservations,
		changed_service_color: initial?.color !== values?.color
	}
}

export type CreateServiceCustomFormChanges = {
	added_industries: number
	added_categories: number
	description_length: number
	added_colleagues: number
	online_reservation: boolean
	auto_approval: boolean
	selected_service_color: 'default' | 'other'
}

export const getGaCreateServiceCustomFormChanges = (
	values: Partial<IServiceCustomForm> | undefined,
	serviceColorsOptions: IServiceColorsPayload['enumerationsOptions'] | undefined
): CreateServiceCustomFormChanges => {
	const defaultColor = serviceColorsOptions?.find((color) => color.extra?.isDefault)?.value
	const color = values?.color

	return {
		added_industries: values?.industryIDs?.length || 0,
		added_categories: values?.categoryIDs?.length || 0,
		description_length: values?.descriptionLocalizations?.defaultLanguage?.length || 0,
		added_colleagues: values?.assignedEmployees?.length || 0,
		online_reservation: !!values?.settings?.enabledB2cReservations,
		auto_approval: !!values?.settings?.autoApproveReservations,
		selected_service_color: color && defaultColor && color === defaultColor ? 'default' : 'other'
	}
}
