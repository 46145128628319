import { z } from 'zod'
import { dateConstraint, selectObjConstraint, stringConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'
import { timeRegex } from '../utils/regex'

export const importedReservationSchema = z.object({
	date: dateConstraint,
	timeFrom: z.string().regex(timeRegex),
	timeTo: z.string().regex(timeRegex),
	note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500)
})

export const reservationsSchema = z.object({
	employee: selectObjConstraint,
	customer: selectObjConstraint,
	service: selectObjConstraint,
	date: dateConstraint,
	timeFrom: z.string().regex(timeRegex),
	timeTo: z.string().regex(timeRegex),
	note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1500)
})
