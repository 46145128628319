import { useEffect } from 'react'

export const TABS_COUNTER_KEY = 'TabsOpen'

type Tabs = Record<string, boolean>

/*
helpers
*/

const getTabs = () => {
	const tabsInfo = localStorage.getItem(TABS_COUNTER_KEY)
	if (tabsInfo !== null) {
		try {
			return JSON.parse(tabsInfo)
		} catch (error) {
			return {}
		}
	}

	return {}
}

const setTabs = (tabs: Tabs) => {
	localStorage.setItem(TABS_COUNTER_KEY, JSON.stringify(tabs))
}

export const getNumberOfTabs = () => {
	return Object.keys(getTabs()).length
}

/*
event handlers
*/

const tabLoadEventHandler = () => {
	const hash = `tab_${+new Date()}`

	sessionStorage.setItem(TABS_COUNTER_KEY, hash)

	const tabs = getTabs()
	tabs[hash] = true
	setTabs(tabs)
}

const tabUnloadEventHandler = () => {
	const hash = sessionStorage.getItem(TABS_COUNTER_KEY)

	const tabs = getTabs()
	if (hash) delete tabs[hash]
	setTabs(tabs)
}

/**
 * Hook for counting the number of opens tabs in the browser and storing
 * them in local storage.
 *
 * For retrieving the number of tabs, use the `getNumberOfTabs` function.
 */
const useTabsCounter = () => {
	useEffect(() => {
		window.addEventListener('load', tabLoadEventHandler)
		window.addEventListener('unload', tabUnloadEventHandler)

		return () => {
			window.removeEventListener('load', tabLoadEventHandler)
			window.removeEventListener('unload', tabUnloadEventHandler)
		}
	}, [])
}

export default useTabsCounter
