import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row, Spin, TableProps } from 'antd'
import { initialize } from 'redux-form'

// components
import CustomTable from '../../components/CustomTable'
import SalonsActiveFilter from './components/filters/SalonsActiveFilter'

// utils
import { FORM, ROW_GUTTER_X_DEFAULT, SALONS_ORDER, SALONS_TAB_KEYS, SALON_CREATE_TYPE, SALON_FILTER_RS_AVAILABLE_ONLINE } from '../../utils/enums'
import { getLinkWithEncodedBackUrl, normalizeDirectionKeys } from '../../utils/helper'
import { SalonsPageCommonProps, getSalonsColumns } from './components/salonUtils'

// reducers
import { getSalons } from '../../reducers/salons/salonsActions'
import { RootState } from '../../reducers'
import { getCategories } from '../../reducers/categories/categoriesActions'

// types
import { GetUrls, ISalonsFilterActive, RequestResponse } from '../../types/interfaces'

// hooks
import useQueryParams from '../../hooks/useQueryParamsZod'

// schema
import { salonsActivePageURLQueryParamsSchema } from '../../schemas/queryParams'

type Props = SalonsPageCommonProps & {}

type TableDataItem = RequestResponse<GetUrls['/api/b2b/admin/salons/']>['salons'][0]

const SalonsActivePage: React.FC<Props> = (props) => {
	const { selectedCountry, t, navigate, dispatch } = props

	const salons = useSelector((state: RootState) => state.salons.salons)
	const categories = useSelector((state: RootState) => state.categories.categories)

	const [query, setQuery] = useQueryParams(salonsActivePageURLQueryParamsSchema, {
		page: 1,
		order: SALONS_ORDER.CREATED_AT_DESC
	})

	const loading = salons.isLoading

	const countryCode = query.countryCode || selectedCountry

	const salonsColumns = getSalonsColumns(query.order, categories.data)

	const columns = [
		salonsColumns.id({ width: '8%' }),
		salonsColumns.name({ width: '15%' }),
		salonsColumns.address({ width: '15%' }),
		salonsColumns.categories({ width: '9%' }),
		salonsColumns.isPublished({ width: '8%' }),
		salonsColumns.changes({ width: '10%' }),
		salonsColumns.createType({ width: '10%' }),
		salonsColumns.enabledRS({ width: '8%' }),
		salonsColumns.availableReservationSystem({ width: '8%' }),
		salonsColumns.upcomingReservationsCount({ width: '8%' }),
		salonsColumns.premiumSourceUserType({ width: '6%' }),
		salonsColumns.assignedUser({ width: '10%' }),
		salonsColumns.fillingProgress({ width: '8%' }),
		salonsColumns.lastUpdatedAt({ width: '8%' }),
		salonsColumns.createdAt({ width: '8%' })
	]

	useEffect(() => {
		dispatch(getCategories())
	}, [dispatch])

	useEffect(() => {
		dispatch(
			initialize(FORM.SALONS_FILTER_ACTIVE, {
				search: query.search,
				statuses_all: query.statuses_all,
				statuses_published: query.statuses_published,
				statuses_changes: query.statuses_changes,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				categoryThirdLevelIDs: query.categoryThirdLevelIDs,
				countryCode,
				createType: query.createType,
				dateFromTo: {
					dateFrom: query.lastUpdatedAtFrom,
					dateTo: query.lastUpdatedAtTo
				},
				hasSetOpeningHours: query.hasSetOpeningHours,
				sourceType: query.sourceType,
				premiumSourceUserType: query.premiumSourceUserType,
				assignedUserID: query.assignedUserID,
				enabledReservationsSetting: query.enabledReservationsSetting,
				hasAvailableReservationSystem: query.hasAvailableReservationSystem,
				hasUpcomingReservations: query.hasUpcomingReservations
			})
		)

		dispatch(
			getSalons({
				page: query.page,
				limit: query.limit,
				search: query.search,
				order: query.order,
				categoryFirstLevelIDs: query.categoryFirstLevelIDs,
				statuses_all: query.statuses_all,
				statuses_published: query.statuses_published,
				statuses_changes: query.statuses_changes,
				countryCode,
				createType: query.createType,
				lastUpdatedAtFrom: query.lastUpdatedAtFrom,
				lastUpdatedAtTo: query.lastUpdatedAtTo,
				hasSetOpeningHours: query.hasSetOpeningHours,
				sourceType: query.sourceType,
				premiumSourceUserType: query.premiumSourceUserType,
				assignedUserID: query.assignedUserID,
				hasAvailableReservationSystem: query.hasAvailableReservationSystem,
				enabledReservationsSetting: query.enabledReservationsSetting,
				salonState: SALONS_TAB_KEYS.ACTIVE,
				categoryThirdLevelIDs: query.categoryThirdLevelIDs,
				hasUpcomingReservations: query.hasUpcomingReservations
			})
		)
	}, [
		dispatch,
		query.page,
		query.limit,
		query.search,
		query.order,
		query.categoryFirstLevelIDs,
		query.statuses_all,
		query.statuses_published,
		query.statuses_changes,
		query.createType,
		query.lastUpdatedAtFrom,
		query.lastUpdatedAtTo,
		query.hasSetOpeningHours,
		query.sourceType,
		query.premiumSourceUserType,
		query.assignedUserID,
		query.hasAvailableReservationSystem,
		query.enabledReservationsSetting,
		query.categoryThirdLevelIDs,
		query.hasUpcomingReservations,
		countryCode
	])

	const handleSubmitActive = (values: ISalonsFilterActive) => {
		const { dateFromTo, ...restValues } = values

		let newQuery = {
			...query,
			...restValues,
			lastUpdatedAtFrom: dateFromTo?.dateFrom,
			lastUpdatedAtTo: dateFromTo?.dateTo,
			page: 1
		}

		if (
			query.hasAvailableReservationSystem !== SALON_FILTER_RS_AVAILABLE_ONLINE.AVAILABLE &&
			!query.createType &&
			values.hasAvailableReservationSystem === SALON_FILTER_RS_AVAILABLE_ONLINE.AVAILABLE
		) {
			newQuery = {
				...newQuery,
				createType: SALON_CREATE_TYPE.NON_BASIC
			}
		}

		setQuery(newQuery)
	}

	const onChangeTable: TableProps<TableDataItem>['onChange'] = (_pagination, _filters, sorter) => {
		if (!(sorter instanceof Array)) {
			const order = `${sorter.columnKey}:${normalizeDirectionKeys(sorter.order)}` as SALONS_ORDER
			const newQuery = {
				...query,
				order
			}
			setQuery(newQuery)
		}
	}

	const onChangePagination = (page: number, limit: number) => {
		const newQuery = {
			...query,
			limit,
			page: limit === salons?.data?.pagination?.limit ? page : 1
		}
		setQuery(newQuery)
	}

	return (
		<>
			<Row gutter={ROW_GUTTER_X_DEFAULT}>
				<Col span={24}>
					<Spin spinning={loading}>
						<div className='content-body'>
							<SalonsActiveFilter onSubmit={handleSubmitActive} query={{ ...query, countryCode }} loading={loading} />
							<CustomTable
								className='table-fixed'
								onChange={onChangeTable}
								columns={columns}
								dataSource={salons?.data?.salons}
								scroll={{ x: 1500 }}
								rowKey='id'
								rowClassName={'clickable-row'}
								twoToneRows
								useCustomPagination
								pagination={{
									pageSize: salons?.data?.pagination?.limit,
									total: salons?.data?.pagination?.totalCount,
									current: salons?.data?.pagination?.page,
									onChange: onChangePagination,
									disabled: loading
								}}
								onRow={(record) => ({
									onClick: () => {
										navigate(getLinkWithEncodedBackUrl(t('paths:salons/{{salonID}}', { salonID: record.id })))
									}
								})}
							/>
						</div>
					</Spin>
				</Col>
			</Row>
		</>
	)
}

export default SalonsActivePage
