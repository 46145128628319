import { z } from 'zod'
import { stringConstraint, imageConstraint, emailConstraint, twoCharsConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH, GENDER } from '../utils/enums'

// eslint-disable-next-line import/prefer-default-export
export const customerSchema = z.object({
	firstName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100, true),
	lastName: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100, true),
	email: emailConstraint.optional(),
	phonePrefixCountryCode: twoCharsConstraint,
	phone: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_20, true),
	note: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1000),
	street: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
	city: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_100),
	zipCode: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_10),
	streetNumber: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_10),
	countryCode: twoCharsConstraint.nullish(),
	gender: z.nativeEnum(GENDER).nullish(),
	gallery: imageConstraint.nullish().array().max(100).optional(),
	avatar: imageConstraint.array().max(1).nullish(),
	isBlacklisted: z.boolean().optional()
})
