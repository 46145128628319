import { jwtDecode as decode } from 'jwt-decode'
import dayjs from 'dayjs'

const ACCESS_TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

export const clearAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY)
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY)
export const setAccessToken = (token: string) => localStorage.setItem(ACCESS_TOKEN_KEY, token)

export const clearRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN_KEY)
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY)
export const setRefreshToken = (token: string) => localStorage.setItem(REFRESH_TOKEN_KEY, token)

export const isLoggedIn = (): boolean => {
	try {
		const token = getAccessToken()
		if (!token) {
			return false
		}
		decode(token)

		return true
	} catch (error) {
		return false
	}
}

export const isTokenValid = (token?: string | null): boolean => {
	if (!token) {
		return false
	}

	try {
		const payload = decode(token)

		const { exp } = payload

		if (!exp || !dayjs.unix(exp).isValid()) {
			return false
		}

		// check if token is expired. 1 minute is buffer to obtain a new valid token
		return dayjs.unix(exp).isAfter(dayjs().add(1, 'minute'))
	} catch (error) {
		return false
	}
}

export const hasRefreshToken = (): boolean => {
	return isTokenValid(getRefreshToken())
}
