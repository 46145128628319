// eslint-disable-next-line import/prefer-default-export
export enum SALONS {
	SALONS_LOAD_START = 'SALONS_LOAD_START',
	SALONS_LOAD_DONE = 'SALONS_LOAD_DONE',
	SALONS_LOAD_FAIL = 'SALONS_LOAD_FAIL'
}

export enum SALON {
	SALON_LOAD_START = 'SALON_LOAD_START',
	SALON_LOAD_DONE = 'SALON_LOAD_DONE',
	SALON_LOAD_FAIL = 'SALON_LOAD_FAIL'
}

export enum SUGGESTED_SALONS {
	SUGGESTED_SALONS_LOAD_START = 'SUGGESTED_SALONS_LOAD_START',
	SUGGESTED_SALONS_LOAD_DONE = 'SUGGESTED_SALONS_LOAD_DONE',
	SUGGESTED_SALONS_LOAD_FAIL = 'SUGGESTED_SALONS_LOAD_FAIL'
}

export enum BASIC_SALONS {
	BASIC_SALONS_LOAD_START = 'BASIC_SALONS_LOAD_START',
	BASIC_SALONS_LOAD_DONE = 'BASIC_SALONS_LOAD_DONE',
	BASIC_SALONS_LOAD_FAIL = 'BASIC_SALONS_LOAD_FAIL'
}

export enum BASIC_SALON {
	BASIC_SALON_LOAD_START = 'BASIC_SALON_LOAD_START',
	BASIC_SALON_LOAD_DONE = 'BASIC_SALON_LOAD_DONE',
	BASIC_SALON_LOAD_FAIL = 'BASIC_SALON_LOAD_FAIL'
}

export enum SALON_HISTORY {
	SALON_HISTORY_LOAD_START = 'SALON_HISTORY_LOAD_START',
	SALON_HISTORY_LOAD_DONE = 'SALON_HISTORY_LOAD_DONE',
	SALON_HISTORY_LOAD_FAIL = 'SALON_HISTORY_LOAD_FAIL'
}

export enum REJECTED_SUGGESTIONS {
	REJECTED_SUGGESTIONS_LOAD_START = 'REJECTED_SUGGESTIONS_LOAD_START',
	REJECTED_SUGGESTIONS_LOAD_DONE = 'REJECTED_SUGGESTIONS_LOAD_DONE',
	REJECTED_SUGGESTIONS_LOAD_FAIL = 'REJECTED_SUGGESTIONS_LOAD_FAIL'
}

export enum SALON_NON_CHECKED_CHANGES {
	SALON_NON_CHECKED_CHANGES_LOAD_START = 'SALON_NON_CHECKED_CHANGES_LOAD_START',
	SALON_NON_CHECKED_CHANGES_LOAD_DONE = 'SALON_NON_CHECKED_CHANGES_LOAD_DONE',
	SALON_NON_CHECKED_CHANGES_LOAD_FAIL = 'SALON_NON_CHECKED_CHANGES_LOAD_FAIL',
	SALON_NON_CHECKED_CHANGES_CLEAR = 'SALON_NON_CHECKED_CHANGES_CLEAR'
}

export enum SALONS_TO_CHECK {
	SALONS_TO_CHECK_LOAD_START = 'SALONS_TO_CHECK_LOAD_START',
	SALONS_TO_CHECK_LOAD_DONE = 'SALONS_TO_CHECK_LOAD_DONE',
	SALONS_TO_CHECK_LOAD_FAIL = 'SALONS_TO_CHECK_LOAD_FAIL'
}
