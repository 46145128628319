import { z } from 'zod'
import { stringConstraint } from './baseSchema'
import { VALIDATION_MAX_LENGTH } from '../utils/enums'

// https://notino-admin.goodrequest.dev/api/doc/#/B2b-%3Eadmin/patchApiB2BAdminSalonsSalonIdB2BVoucher
// eslint-disable-next-line import/prefer-default-export
export const voucherSchema = z.object({
	code: stringConstraint(VALIDATION_MAX_LENGTH.LENGTH_1000, true),
	voucherIndex: z.union([z.literal(1), z.literal(2)])
})
