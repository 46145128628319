import React, { FC } from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { Form } from 'antd'
import { debounce } from 'lodash'

// utils
import { showErrorNotification } from '../../../utils/helper'
import { CHANGE_DEBOUNCE_TIME, FORM } from '../../../utils/enums'

// components
import { LanguagePickerField } from '../../../components/LanguagePicker'

// types
import { IAccountSettingsForm } from '../../../types/interfaces'

// validation
import { validationAccountSettingsFn } from '../../../utils/formValidation'

type ComponentProps = {}

type Props = InjectedFormProps<IAccountSettingsForm, ComponentProps> & ComponentProps

const AccountSettingsForm: FC<Props> = (props) => {
	const { handleSubmit } = props

	return (
		<Form onSubmitCapture={handleSubmit} layout={'vertical'} className={'form'}>
			<LanguagePickerField name={'languageCode'} className={'pb-6 z-50'} />
		</Form>
	)
}

const form = reduxForm<IAccountSettingsForm, ComponentProps>({
	form: FORM.ACCOUNT_SETTINGS,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	onChange: debounce((_values, _dispatch, { submit, anyTouched }) => {
		if (anyTouched) {
			submit()
		}
	}, CHANGE_DEBOUNCE_TIME),
	destroyOnUnmount: true,
	validate: validationAccountSettingsFn,
	onSubmitFail: showErrorNotification
})(AccountSettingsForm)

export default form
