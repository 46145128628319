import React from 'react'
import { BaseFieldsProps, WrappedFieldsProps } from 'redux-form'
import { Gutter } from 'antd/es/grid/row'
import { get, map } from 'lodash'
import cx from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import { Col, Form, Row, TimePicker } from 'antd'
import { TimePickerProps } from 'antd/lib/time-picker'

// assets
import TimerIcon from '../assets/icons/clock-icon.svg?react'
import RemoveIcon from '../assets/icons/remove-select-icon.svg?react'

// utils
import { formFieldID } from '../utils/helper'
import { DEFAULT_TIME_FORMAT, DROPDOWN_POSITION, ROW_GUTTER_X_DEFAULT, TIME_FORMAT_12_HOUR } from '../utils/enums'
import { is12HourFormat } from '../utils/intl'
import { TimeRangeFieldCustomOnChangeFncArgs } from '../types/interfaces'

type Props = WrappedFieldsProps &
	TimePickerProps &
	BaseFieldsProps & {
		placeholders: string[]
		labels?: string[]
		timeFormat?: string
		itemClassName?: string
		hideHelp?: boolean
		layout: 'row' | 'col'
		customOnChange?: (data: TimeRangeFieldCustomOnChangeFncArgs) => void
		gutter?: Gutter
	}

const { Item } = Form

const TimeRangeField = (props: Props) => {
	const {
		names,
		placeholders,
		labels,
		disabled,
		allowClear,
		minuteStep,
		getPopupContainer,
		required,
		size,
		itemClassName,
		timeFormat,
		hideHelp,
		suffixIcon,
		use12Hours = is12HourFormat(),
		layout = 'row',
		className,
		customOnChange,
		gutter = ROW_GUTTER_X_DEFAULT
	} = props

	const defaultValueFormat = timeFormat || DEFAULT_TIME_FORMAT

	const allowClearWrap = () => {
		if (typeof allowClear === 'object' && allowClear.clearIcon) {
			return allowClear
		}

		if (allowClear) {
			return {
				clearIcon: <RemoveIcon className={'text-notino-black'} />
			}
		}

		return false
	}

	return (
		<Row gutter={gutter} className={className}>
			{map(names, (name, index: number) => {
				const meta = get(props, `${name}.meta`) as any
				const input = get(props, `${name}.input`) as any
				const inputRef = React.createRef<any>()

				let pickerValue: Dayjs | undefined
				if (input.value) {
					pickerValue = dayjs(input.value, defaultValueFormat)
				}

				const onChangeWrap = (valueWithSeconds: Dayjs) => {
					// NOTE: neporovnavaj sekundy, ak kliknes na dropdown tlacidlo "Teraz"
					const value = valueWithSeconds.set('seconds', 0).set('milliseconds', 0)

					if (customOnChange) {
						let currentTimeFrom = props[names[0]].input.value
						if (currentTimeFrom) {
							currentTimeFrom = dayjs(currentTimeFrom, defaultValueFormat).set('seconds', 0).set('milliseconds', 0)
						}

						let currentTimeTo = props[names[1]].input.value
						if (currentTimeTo) {
							currentTimeTo = dayjs(currentTimeTo, defaultValueFormat).set('seconds', 0).set('milliseconds', 0)
						}

						const newTimeFrom = index === 0 ? value : currentTimeFrom
						const newTimeTo = index === 1 ? value : currentTimeTo

						customOnChange({ newTimeFrom, newTimeTo, currentTimeFrom, currentTimeTo, index })
						return
					}

					let newValue = value.format(defaultValueFormat)
					const other = dayjs(get(props, `${names[index === 0 ? 1 : 0]}.input.value`) as any, defaultValueFormat)
					let isNorm

					if (index === 0 && value >= other) {
						newValue = other.subtract(1, 'minute').format(defaultValueFormat)
						isNorm = true
					} else if (index === 1 && value <= other) {
						newValue = other.set('minutes', other.minute() + 1).format(defaultValueFormat)
						isNorm = true
					}

					// NOTE: blurni input aby sa normalizovana hodnota prejavila hned po vybere
					if (isNorm) {
						inputRef?.current?.blur()
					}

					input.onChange(newValue)
				}

				const onClear = (value: Dayjs | null) => {
					if (!value) {
						input.onChange(null)
					} else {
						onChangeWrap(value)
					}
				}

				return (
					<Col span={layout === 'row' ? 12 : 24} key={name}>
						<Item
							className={cx('w-full', itemClassName, { 'pb-4': layout === 'col' && index === 0 })}
							label={labels?.[index]}
							required={required}
							help={hideHelp ? undefined : meta.touched && meta.error}
							validateStatus={meta.touched && meta.error ? 'error' : undefined}
						>
							<TimePicker
								ref={inputRef}
								id={formFieldID(meta.form, input.name)}
								popupAlign={DROPDOWN_POSITION.BOTTOM_LEFT}
								// NOTE: workaround https://github.com/ant-design/ant-design/issues/21189
								onCalendarChange={(value) => {
									if (value) {
										onChangeWrap(Array.isArray(value) ? value[0] : value)
									}
								}}
								onChange={onClear}
								format={timeFormat || use12Hours ? TIME_FORMAT_12_HOUR : DEFAULT_TIME_FORMAT}
								value={pickerValue}
								className={'w-full noti-date-input noti-time-input'}
								popupClassName={'noti-time-dropdown'}
								size={size}
								suffixIcon={suffixIcon || <TimerIcon className={'text-notino-black'} />}
								placeholder={placeholders[index] as any}
								disabled={disabled}
								allowClear={allowClearWrap()}
								minuteStep={minuteStep}
								use12Hours={use12Hours}
								getPopupContainer={getPopupContainer || ((node: any) => node)}
							/>
						</Item>
					</Col>
				)
			})}
		</Row>
	)
}

export default TimeRangeField
