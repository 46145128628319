import React, { useEffect, useState } from 'react'
import { Field, InjectedFormProps, WrappedFieldProps } from 'redux-form'
import { Alert, Col, Row, Spin } from 'antd'
import cx from 'classnames'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { APIProvider, useApiIsLoaded, useApiLoadingStatus } from '@vis.gl/react-google-maps'
import { isNil } from 'lodash'

// utils
import { FORM, MAP_CONFIG, MAP_LOADING_STATUS, VALIDATION_MAX_LENGTH } from '../utils/enums'
import { optionRenderWithImage, parseAddressComponents } from '../utils/helper'

// atoms
import InputField from '../atoms/InputField'
import SelectField from '../atoms/SelectField'
import InputNumberField from '../atoms/InputNumberField'

// redux
import { RootState } from '../reducers'

// assets
import GlobeIcon from '../assets/icons/globe-icon.svg?react'

// dynamic imports
const StandaloneSearchBoxField = React.lazy(() => import('../atoms/StandaloneSearchBoxField'))
const MapContainer = React.lazy(() => import('./MapContainer'))

type Props = WrappedFieldProps & {
	inputValues: {
		latitude: number
		longitude: number
		city?: string | null
		street?: string | null
		streetNumber?: string | null
		zipCode?: string | null
		country?: string | null
		address?: string | null
	} | null
	toCheckValues: {
		latitude?: boolean
		longitude: boolean
		city?: boolean
		street?: boolean
		streetNumber?: boolean
		zipCode?: boolean
		country?: boolean
		address?: boolean
	} | null
	changeFormFieldValue: InjectedFormProps['change']
	disabled?: boolean
	form?: FORM
}

const AddressFields = (props: Props) => {
	const {
		changeFormFieldValue,
		inputValues,
		input,
		meta: { form, error, touched },
		disabled,
		toCheckValues
	} = props

	const { t } = useTranslation()

	const config = useSelector((state: RootState) => state.config.config)
	const rolloutCountriesCodes = config.data?.rolloutCountries.map((country) => country.code)

	const [mapError, setMapError] = useState<boolean>(false)
	const mapLoadingStatus = useApiLoadingStatus()
	const isLoaded = useApiIsLoaded()

	useEffect(() => {
		if (mapLoadingStatus === MAP_LOADING_STATUS.AUTH_FAILURE || mapLoadingStatus === MAP_LOADING_STATUS.FAILED) {
			setMapError(true)
		}
	}, [mapLoadingStatus])

	const parseAddressObject = (addressComponents: google.maps.places.PlaceResult['address_components']) => {
		const address = parseAddressComponents(addressComponents)

		const { streetNumber, houseNumber, street, city, country, zip } = address

		changeFormFieldValue('city', city)
		changeFormFieldValue('country', country)
		changeFormFieldValue('zipCode', zip)

		if (streetNumber) {
			changeFormFieldValue('streetNumber', streetNumber)
			changeFormFieldValue('street', street)
		} else if (houseNumber) {
			changeFormFieldValue('streetNumber', houseNumber)
			changeFormFieldValue('street', city)
		} else {
			changeFormFieldValue('streetNumber', '')
			changeFormFieldValue('street', street)
		}
	}

	const onPlaceSelected = (place: google.maps.places.PlaceResult | null) => {
		if (!place) {
			return
		}

		parseAddressObject(place.address_components)
		if (place.geometry?.location) {
			changeFormFieldValue('latitude', parseFloat(place.geometry.location.lat().toFixed(8)))
			changeFormFieldValue('longitude', parseFloat(place.geometry.location.lng().toFixed(8)))
			changeFormFieldValue('zoom', MAP_CONFIG.placeZoom)
		}
	}

	const onLocationChange = async ({ lat: newLat, lng: newLng }: { lat: number | undefined; lng: number | undefined }) => {
		if (isNil(newLat) || isNil(newLng)) {
			changeFormFieldValue('latitude', MAP_CONFIG.defaultLocation.lat)
			changeFormFieldValue('longitude', MAP_CONFIG.defaultLocation.lng)
			return
		}

		const lat = parseFloat(newLat.toFixed(8))
		const lng = parseFloat(newLng.toFixed(8))

		changeFormFieldValue('latitude', lat)
		changeFormFieldValue('longitude', lng)

		// reverse geocoding
		try {
			if (window.google?.maps) {
				const geocoder = new window.google.maps.Geocoder()
				const response = await geocoder.geocode({
					location: {
						lat,
						lng
					}
				})

				if (response) {
					parseAddressObject(response.results[0].address_components)
					setMapError(false)
				} else {
					setMapError(true)
				}
			}
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err)
			setMapError(true)
		}
	}

	if ((!isLoaded && !mapError) || config.isLoading) {
		return <Spin className={'w-full'} />
	}

	return (
		<>
			<Row className={'mb-4 gap-4'} wrap={false}>
				{mapError || !MAP_CONFIG.googleMapsApiKey ? (
					<Row className={'w-full h-full block'} justify='center'>
						<Alert message={t('loc:Google mapa je aktuálne nedostupná.')} showIcon type={'warning'} className={'noti-alert mb-4 google-map-warning'} />
						<Row justify={'space-between'}>
							<Field
								className={'w-4/6'}
								component={InputField}
								label={t('loc:Ulica')}
								placeholder={t('loc:Zadajte ulicu')}
								name={'street'}
								size={'large'}
								disabled={disabled}
								required
								toCheck={toCheckValues?.street}
							/>
							<Field
								className={'w-3/10'}
								component={InputField}
								label={t('loc:Popisné číslo')}
								placeholder={t('loc:Zadajte číslo')}
								name={'streetNumber'}
								disabled={disabled}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_10}
								size={'large'}
								toCheck={toCheckValues?.streetNumber}
							/>
						</Row>
						<Row justify={'space-between'}>
							<Field
								className={'w-12/25'}
								component={InputField}
								label={t('loc:Mesto')}
								placeholder={t('loc:Zadajte mesto')}
								name={'city'}
								size={'large'}
								disabled={disabled}
								required
								toCheck={toCheckValues?.city}
							/>
							<Field
								className={'w-12/25'}
								component={InputField}
								label={t('loc:PSČ')}
								placeholder={t('loc:Zadajte smerovacie číslo')}
								name={'zipCode'}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_10}
								size={'large'}
								disabled={disabled}
								required
								toCheck={toCheckValues?.zipCode}
							/>
						</Row>
						<Row justify={'space-between'}>
							<Field
								className={'w-1/4'}
								component={SelectField}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
								label={t('loc:Štát')}
								placeholder={t('loc:Vyber krajinu')}
								options={config.optionsRolloutCountries}
								name={'country'}
								size={'large'}
								disabled={disabled}
								loading={config.isLoading}
								required
								toCheck={toCheckValues?.country}
							/>
							<Field
								className={'w-1/3'}
								component={InputNumberField}
								label={t('loc:Zem. dĺžka')}
								placeholder={t('loc:Zem. dĺžka v rozsahu od -180 do 180')}
								name='longitude'
								size={'large'}
								required
								maxChars={10}
								disabled={disabled}
								toCheck={toCheckValues?.longitude}
							/>
							<Field
								className={'w-1/3'}
								component={InputNumberField}
								label={t('loc:Zem. šírka')}
								placeholder={t('loc:Zem. šírka v rozsahu od -90 do 90')}
								name='latitude'
								size={'large'}
								required
								maxChars={10}
								disabled={disabled}
								toCheck={toCheckValues?.latitude}
							/>
						</Row>
					</Row>
				) : (
					<div className={'mb-7 flex-1 w-1/2 xl:w-full'}>
						<Row>
							<Col span={24} className={'mb-7'}>
								<StandaloneSearchBoxField
									label={t('loc:Vyhľadať')}
									required
									onPlaceSelected={onPlaceSelected}
									placeholder={t('loc:Vyhľadajte miesto na mape')}
									className={'mb-0 pb-0'}
									error={error && touched}
									disabled={disabled}
									form={form}
									name={input.name}
									toCheck={toCheckValues?.address}
									allowedCountries={rolloutCountriesCodes}
								/>
								<div className={cx('text-danger', { hidden: !(error && touched) })}>{error}</div>
							</Col>
							<Col span={24}>
								<Row gutter={[8, 8]}>
									<Col span={8}>
										<Field
											component={InputField}
											label={t('loc:Ulica')}
											placeholder={t('loc:Zadajte ulicu')}
											name={'street'}
											size={'large'}
											disabled={disabled}
											toCheck={toCheckValues?.street}
											required
										/>
										<Field
											component={InputField}
											label={t('loc:Mesto')}
											placeholder={t('loc:Zadajte mesto')}
											name={'city'}
											toCheck={toCheckValues?.city}
											size={'large'}
											disabled={disabled}
											required
										/>
										<Row gutter={[8, 8]}>
											<Col span={12} sm={24} md={24} lg={12}>
												<Field
													component={InputField}
													label={t('loc:PSČ')}
													placeholder={t('loc:Zadajte smerovacie číslo')}
													name={'zipCode'}
													maxLength={VALIDATION_MAX_LENGTH.LENGTH_10}
													size={'large'}
													disabled={disabled}
													toCheck={toCheckValues?.zipCode}
													required
												/>
											</Col>
											<Col span={12} sm={24} md={24} lg={12}>
												<Field
													component={InputField}
													label={t('loc:Popisné číslo')}
													placeholder={t('loc:Zadajte číslo')}
													maxLength={VALIDATION_MAX_LENGTH.LENGTH_10}
													name={'streetNumber'}
													disabled={disabled}
													size={'large'}
													toCheck={toCheckValues?.streetNumber}
												/>
											</Col>
										</Row>
										<Field
											component={SelectField}
											optionRender={(itemData: any) => optionRenderWithImage(itemData, <GlobeIcon />)}
											label={t('loc:Štát')}
											placeholder={t('loc:Vyber krajinu')}
											options={config.optionsRolloutCountries}
											name={'country'}
											size={'large'}
											disabled={disabled}
											loading={config.isLoading}
											toCheck={toCheckValues?.country}
											required
										/>
									</Col>
									<Col span={16}>
										<MapContainer
											onLocationChange={onLocationChange}
											lat={inputValues?.latitude}
											lng={inputValues?.longitude}
											disabled={disabled}
											toCheck={toCheckValues?.latitude || toCheckValues?.longitude}
										/>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
				)}
			</Row>
		</>
	)
}

// NOTE: This component is now only used in one place in the application.
// If it will be used in several places, it is necessary to ensure that the provider is imported only once per site.
const AddressFieldsProvider = (props: Props) => {
	return (
		<APIProvider apiKey={MAP_CONFIG.googleMapsApiKey}>
			<AddressFields {...props} />
		</APIProvider>
	)
}

export default AddressFieldsProvider
