import React, { FC, useCallback, useMemo } from 'react'
import { Field, FieldArray, FormSection, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'
import { Col, Divider, Form, Row, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import i18next from 'i18next'

// components
import OpeningHours from '../../../../components/OpeningHours/OpeningHours'
import AddressFields from '../../../../components/AddressFields'
import QrCode from '../../../../components/QrCode'
import NewBadge from '../../../../components/NewBadge'

// atoms
import InputField from '../../../../atoms/InputField'
import SwitchField from '../../../../atoms/SwitchField'
import TextareaField from '../../../../atoms/TextareaField'
import ImgUploadField from '../../../../atoms/ImgUploadField'
import SelectField from '../../../../atoms/SelectField'
import PhoneArrayField from '../../../../atoms/PhoneArrayField'
import AutocompleteField from '../../../../atoms/AutocompleteField'

// utils
import { getUploadLimitsMessage, optionRenderWithImage, showErrorNotification } from '../../../../utils/helper'
import {
	FILTER_ENTITY,
	FORM,
	PAGE,
	PERMISSION,
	SALON_DETAIL_FORM_SECTION,
	SALON_STATES,
	SET_OF_COUNTRIES,
	UPLOAD,
	UPLOAD_IMG_CATEGORIES,
	URL_UPLOAD_FILE,
	VALIDATION_MAX_LENGTH
} from '../../../../utils/enums'
import { withPromptUnsavedChanges } from '../../../../utils/promptUnsavedChanges'
import { getSalonTagChanges, getSalonTagDeleted, getSalonTagPublished, getSalonTagSourceType, getValueToCheckUploadField } from '../salonUtils'
import searchWrapper from '../../../../utils/filters'
import { checkPermissions } from '../../../../utils/Permissions'
import { pushEventToDataLayer } from '../../../../utils/dataLayer'
import { DATA_LAYER_EVENTS, GA_SCREEN_NAME } from '../../../../utils/dataLayerEnums'

// types
import { ISalonForm, ISelectOptionItem } from '../../../../types/interfaces'
import { RootState } from '../../../../reducers'
import { ISelectedSalonPayload } from '../../../../reducers/selectedSalon/selectedSalonActions'
import { ISalonNonCheckedChangesPayload } from '../../../../reducers/salons/salonsActions'

// assets
import InstagramIcon from '../../../../assets/icons/social-instagram-icon.svg?react'
import FacebookIcon from '../../../../assets/icons/social-facebook-icon.svg?react'
import CreditCardIcon from '../../../../assets/icons/credit-card-icon.svg?react'
import InfoIcon from '../../../../assets/icons/info-icon.svg?react'
import PhoneIcon from '../../../../assets/icons/phone-2-icon.svg?react'
import TimerIcon from '../../../../assets/icons/clock-icon.svg?react'
import GlobeIcon from '../../../../assets/icons/globe-icon.svg?react'
import SocialIcon from '../../../../assets/icons/social-icon.svg?react'
import SocialPinterest from '../../../../assets/icons/social-pinterest.svg?react'
import SocialYoutube from '../../../../assets/icons/social-youtube.svg?react'
import SocialTikTok from '../../../../assets/icons/social-tiktok.svg?react'
import CosmeticIcon from '../../../../assets/icons/cosmetic-icon.svg?react'
import LanguagesIcon from '../../../../assets/icons/languages-icon.svg?react'
import LocationIcon from '../../../../assets/icons/location-icon.svg?react'
import QrCodeIcon from '../../../../assets/icons/qr-code-icon.svg?react'

// validation
import { validationSalonFn } from '../../../../utils/formValidation'

type ComponentProps = {
	disabledForm?: boolean
	noteModalControlButtons?: React.ReactNode
	voucherModalControlButtons?: React.ReactNode
	notinoUserModalControlButtons?: React.ReactNode
	deletedSalon?: boolean
	loadBasicSalon?: (id: string) => void
	clearSalonForm?: () => void
	searchSalons?: (search: string, page: number) => void
	showBasicSalonsSuggestions?: boolean
	salonData?: ISelectedSalonPayload['data']
	nonCheckedChanges?: NonNullable<ISalonNonCheckedChangesPayload['data']>['changes']
	isNotinoUser?: boolean
}

type Props = InjectedFormProps<ISalonForm, ComponentProps> & ComponentProps

export const optionRenderSalonSearch = (itemData: any) => {
	const { label, extra } = itemData
	const address = extra?.salon?.address

	return (
		<div className='noti-salon-search-option-render'>
			<span className={'label'}>{label}</span>
			<div className={'address'}>
				{!isEmpty(address) ? (
					<>
						{address?.street && (
							<>
								{address?.street} {address?.streetNumber}
							</>
						)}
						{(address?.zipCode || address?.city) && ', '}
						{address?.zipCode} {address?.city}
						{`, ${address?.countryCode}`}
					</>
				) : (
					<i>{i18next.t('loc:Adresa salónu nie je známa')}</i>
				)}
			</div>
		</div>
	)
}

const SalonForm: FC<Props> = (props) => {
	const [t] = useTranslation()
	const {
		handleSubmit,
		change,
		noteModalControlButtons,
		disabledForm,
		loadBasicSalon,
		clearSalonForm,
		searchSalons,
		showBasicSalonsSuggestions,
		deletedSalon,
		notinoUserModalControlButtons,
		salonData,
		voucherModalControlButtons,
		nonCheckedChanges,
		isNotinoUser
	} = props
	const dispatch = useDispatch()
	const languages = useSelector((state: RootState) => state.languages.languages)
	const cosmetics = useSelector((state: RootState) => state.cosmetics.cosmetics)
	const formValues = useSelector((state: RootState) => state.form?.[FORM?.SALON]?.values) as ISalonForm | undefined
	const authUserPermissions = useSelector((state: RootState) => state.user?.authUser?.data?.uniqPermissions || [])
	const changes = nonCheckedChanges || {}

	const hasRawPermissions = useMemo(
		() => checkPermissions(authUserPermissions, [PERMISSION.NOTINO_ADMIN, PERMISSION.NOTINO_SUPER_ADMIN, PERMISSION.NOTINO]),
		[authUserPermissions]
	)

	const cosmeticsInitOptions = salonData?.cosmetics?.reduce((acc, cosmetic) => {
		if (cosmetic !== undefined) {
			return [
				...acc,
				{
					label: cosmetic.name,
					value: cosmetic.id,
					extra: { image: cosmetic.image?.resizedImages.thumbnail },
					key: `Cosmetic_${cosmetic.id}`
				}
			]
		}
		return acc
	}, [] as ISelectOptionItem[])

	const languagesInitOptions = salonData?.languages?.reduce((acc, lng) => {
		if (lng !== undefined) {
			return [
				...acc,
				{
					label: lng.name || '',
					value: lng.id,
					extra: { image: lng.image?.resizedImages.thumbnail },
					key: `language_${lng.id}`
				}
			]
		}
		return acc
	}, [] as ISelectOptionItem[])

	const searchCosmetics = useCallback(
		async (search: string, page: number) => {
			return searchWrapper(dispatch, { search, limit: 100, page }, FILTER_ENTITY.COSMETICS)
		},
		[dispatch]
	)

	return (
		<Form layout={'vertical'} className={'form'} onSubmitCapture={handleSubmit}>
			<Space className={'w-full'} direction='vertical' size={36}>
				<FormSection name={SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION}>
					<Row>
						<Col span={24}>
							<Row justify={'space-between'}>
								<h3 className={'mb-0 mt-0 flex items-center'}>
									<InfoIcon className={'text-notino-black medium-icon mr-2'} />
									{t('loc:Základné údaje')}
								</h3>
								<Row className={'py-2'} wrap={false}>
									{!deletedSalon ? (
										<>
											{getSalonTagSourceType(salonData?.premiumSourceUserType)}
											{getSalonTagPublished(salonData?.state as SALON_STATES)}
											{getSalonTagChanges(salonData?.state as SALON_STATES)}
										</>
									) : (
										getSalonTagDeleted(true, true)
									)}
								</Row>
							</Row>
							<Divider className={'mb-3 mt-3'} />
							{showBasicSalonsSuggestions ? (
								<Field
									component={AutocompleteField}
									label={t('loc:Názov')}
									placeholder={t('loc:Vyhľadajte salón podľa názvu alebo zadajte vlastný')}
									name={'name'}
									filterOption={false}
									showSearch
									onDidMountSearch
									required
									size={'large'}
									optionRender={optionRenderSalonSearch}
									allowClear
									labelInValue
									onSearch={searchSalons}
									onSelect={(_value: string | ISelectOptionItem, option: ISelectOptionItem) => {
										if (option?.extra?.salon?.id && loadBasicSalon) {
											loadBasicSalon(option?.extra.salon.id)
										}
									}}
									onClear={clearSalonForm}
									allowInfinityScroll
								/>
							) : (
								<Field
									component={InputField}
									label={t('loc:Názov')}
									placeholder={t('loc:Zadajte názov')}
									name={'name'}
									size={'large'}
									disabled={disabledForm}
									required
									toCheck={isNotinoUser && changes['salonData.name']?.changed}
								/>
							)}
							<Field
								component={TextareaField}
								label={t('loc:O nás')}
								name={'aboutUsFirst'}
								size={'large'}
								placeholder={t('loc:Zadajte základné informácie o salóne')}
								disabled={disabledForm}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_1000}
								toCheck={isNotinoUser && changes['salonData.aboutUsFirst']?.changed}
								showLettersCount
							/>
							{notinoUserModalControlButtons}
							<Field
								component={SelectField}
								options={languages.enumerationsOptions}
								initialOptions={languagesInitOptions}
								label={t('loc:Jazyky, ktorými sa dá v salóne dohovoriť')}
								placeholder={t('loc:Vyberte jazyk')}
								name={'languageIDs'}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <LanguagesIcon />)}
								size={'large'}
								showSearch
								filterOption={true}
								loading={languages.isLoading}
								mode={'multiple'}
								disabled={disabledForm}
								allowClear
							/>
							<Field
								component={SelectField}
								initialOptions={cosmeticsInitOptions}
								label={t('loc:Kozmetika')}
								placeholder={t('loc:Vyberte kozmetiku')}
								name={'cosmeticIDs'}
								optionRender={(itemData: any) => optionRenderWithImage(itemData, <CosmeticIcon />, 40)}
								showSearch
								onSearch={searchCosmetics}
								filterOption={false}
								size={'large'}
								loading={cosmetics.isLoading}
								mode={'multiple'}
								disabled={disabledForm}
								allowClear
								allowInfinityScroll
							/>
							<Field
								component={ImgUploadField}
								name={'logo'}
								label={t('loc:Logo')}
								maxFileSize={UPLOAD.SALON_LOGO.MAX_FILE_SIZE}
								accept={UPLOAD.SALON_LOGO.ACCEPT}
								labelDescription={getUploadLimitsMessage(UPLOAD.SALON_LOGO.ACCEPT, UPLOAD.SALON_LOGO.MAX_FILE_SIZE)}
								signUrl={URL_UPLOAD_FILE}
								multiple={false}
								maxCount={UPLOAD.SALON_LOGO.MAX_COUNT}
								category={UPLOAD_IMG_CATEGORIES.SALON_LOGO}
								disabled={disabledForm}
								toCheck={
									isNotinoUser
										? {
												changed: changes['salonData.logoID']?.changed,
												addedFilesIDs: changes['salonData.logoID']?.new || []
											}
										: undefined
								}
							/>
							<Field
								component={ImgUploadField}
								name={'gallery'}
								label={t('loc:Fotogaléria')}
								maxFileSize={UPLOAD.SALON_GALLERY.MAX_FILE_SIZE}
								accept={UPLOAD.SALON_GALLERY.ACCEPT}
								labelDescription={getUploadLimitsMessage(
									UPLOAD.SALON_GALLERY.ACCEPT,
									UPLOAD.SALON_GALLERY.MAX_FILE_SIZE,
									t('loc:Poradie fotiek môžete zmeniť ich presunutím pomocou Drag & Drop.')
								)}
								maxCount={UPLOAD.SALON_GALLERY.MAX_COUNT}
								signUrl={URL_UPLOAD_FILE}
								multiple
								hasRawPermissions={hasRawPermissions}
								category={UPLOAD_IMG_CATEGORIES.SALON_IMAGE}
								disabled={disabledForm}
								draggable
								selectable
								toCheck={
									isNotinoUser
										? {
												changed: changes['salonData->images.id']?.changed,
												addedFilesIDs: getValueToCheckUploadField(changes['salonData->images.id'])
											}
										: undefined
								}
							/>
						</Col>
					</Row>
				</FormSection>
				<Row>
					<Col span={24}>
						<div className={'flex items-center'}>
							<h3 className={'mb-0 flex items-center'}>
								<QrCodeIcon width={20} height={20} className={'text-notino-black mr-2'} />
								{t('loc:QR kódy')}
							</h3>
							<NewBadge pageEnum={PAGE.SALONS} className={'ml-4'} />
						</div>
						<Divider className={'mb-3 mt-3'} />
						<p className={'text-xs text-notino-grayDark'}>
							{t(
								'loc:Po naskenovaní QR kódu sa vaši zákazníci dostanú priamo na váš profil v zákazníckej aplikácii Notino, vďaka čomu si u vás rezervujú termín ešte rýchlejšie a pohodlnejšie.'
							)}
						</p>
					</Col>
					{salonData?.qrCodes?.map((qrCode, index) => {
						return (
							<Col key={index} xl={12} md={24}>
								<QrCode
									description={qrCode.description}
									link={qrCode.link}
									name={qrCode.name}
									entityUrl={qrCode.entityUrl}
									onDownload={() => {
										pushEventToDataLayer({
											event: DATA_LAYER_EVENTS.CLICK_BUTTON,
											button_name: 'download_digital_qr_code',
											screen_name: GA_SCREEN_NAME.SALON_DETAIL
										})
									}}
									onCopy={() => {
										pushEventToDataLayer({
											event: DATA_LAYER_EVENTS.CLICK_BUTTON,
											button_name: 'copy_url_link',
											screen_name: GA_SCREEN_NAME.SALON_DETAIL
										})
									}}
								/>
							</Col>
						)
					})}
				</Row>
				<FormSection name={SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION}>
					<Row>
						<Col span={24}>
							<h3 className={'mb-0 flex items-center'}>
								<PhoneIcon width={20} height={20} className={'text-notino-black mr-2'} />
								{t('loc:Kontaktné údaje')}
							</h3>
							<Divider className={'mb-3 mt-3'} />
							<FieldArray
								component={PhoneArrayField}
								name={'phones'}
								props={{
									disabled: disabledForm,
									requiredAtLeastOne: true,
									toCheck: isNotinoUser
										? {
												changed: !!changes['salonData->phones.id']?.changed,
												addedFilesIDs: getValueToCheckUploadField(changes['salonData->phones.id'])
											}
										: undefined,
									countries: SET_OF_COUNTRIES.ROLLOUT
								}}
							/>
							<Field
								className={'w-full'}
								component={InputField}
								label={t('loc:Email')}
								placeholder={t('loc:Zadajte email')}
								name={'email'}
								size={'large'}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.email']?.changed}
							/>
						</Col>
					</Row>
				</FormSection>
				<FormSection name={SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION}>
					<Row>
						<Col span={24}>
							<h3 className={'mb-0 flex items-center'}>
								<LocationIcon className={'text-notino-black mr-2 medium-icon'} />
								{t('loc:Adresa')}
							</h3>
							<Divider className={'mb-3 mt-3'} />
							<Field
								component={AddressFields}
								inputValues={{
									latitude: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.latitude,
									longitude: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.longitude,
									city: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.city,
									street: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.street,
									streetNumber: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.streetNumber,
									zipCode: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.zipCode,
									country: formValues?.[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]?.country
								}}
								toCheckValues={
									isNotinoUser
										? {
												latitude: changes['salonData->address.latitude']?.changed,
												longitude: changes['salonData->address.longitude']?.changed,
												city: changes['salonData->address.city']?.changed,
												street: changes['salonData->address.street']?.changed,
												streetNumber: changes['salonData->address.streetNumber']?.changed,
												zipCode: changes['salonData->address.zipCode']?.changed,
												country: changes['salonData->address.countryCode']?.changed
											}
										: null
								}
								changeFormFieldValue={(fieldName: string, value: any) => change(`${[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]}.${fieldName}`, value)}
								disabled={disabledForm}
								name={'address'}
							/>
							<Field
								component={TextareaField}
								label={t('loc:Poznámka k adrese')}
								name={'locationNote'}
								size={'large'}
								placeholder={t('loc:Zadajte poznámku k adrese, napr. “3. poschodie vľavo”')}
								disabled={disabledForm}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_1000}
								showLettersCount
								toCheck={isNotinoUser && changes['salonData.locationNote']?.changed}
							/>
							<Field
								component={TextareaField}
								label={t('loc:Poznámka k parkovaniu')}
								name={'parkingNote'}
								className={'pb-0'}
								size={'large'}
								placeholder={t('loc:Zadajte poznámku k parkovaniu, napr. “Parkovanie oproti budove.”')}
								disabled={disabledForm}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_1000}
								showLettersCount
								toCheck={isNotinoUser && changes['salonData.parkingNote']?.changed}
							/>
						</Col>
					</Row>
				</FormSection>
				{/** NOTE: there are some problems with OpeningHours component when using FormSection, so it's done differently then the other parts of the form */}
				{/* <FormSection name={SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION}> */}
				<Row>
					<Col span={24}>
						<h3 className={'mb-0 flex items-center'}>
							<TimerIcon width={24} height={24} className={'text-notino-black mr-2'} /> {t('loc:Otváracie hodiny')}
						</h3>
						<Divider className={'mb-3 mt-3'} />
						{salonData?.settings.enabledReservations && (
							<p className={'text-notino-grayDark text-sm mb-3'}>
								{t('loc:Novým zamestnancom sa nastaví pracovná doba automaticky podľa otváracích hodín salónu. Môžete ju zmeniť v detaile zamestnanca.')}
							</p>
						)}
						<Field
							className={'pb-0'}
							component={SwitchField}
							label={t('loc:Pon - Pi rovnaké otváracie hodiny')}
							name={`${SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION}.sameOpenHoursOverWeek`}
							size={'middle'}
							disabled={disabledForm}
						/>
						<FieldArray
							component={OpeningHours}
							name={`${SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION}.openingHours`}
							props={{ disabled: disabledForm, showOnDemand: true, openOverWeekendFieldName: `${SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION}.openOverWeekend` }}
						/>
						{noteModalControlButtons}
					</Col>
				</Row>
				{/* </FormSection> */}
				<FormSection name={SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION}>
					<Row>
						<Col span={24}>
							<h3 className={'mb-0 flex items-center'}>
								<CreditCardIcon className={'text-notino-black mr-2'} />
								{t('loc:Možnosti platby')}
							</h3>
							<Divider className={'mb-3 mt-3'} />
							<Row justify={'space-between'}>
								<Field
									className={'w-12/25'}
									component={SwitchField}
									label={t('loc:Platba v hotovosti')}
									name={'payByCash'}
									size={'middle'}
									disabled={disabledForm}
									required
								/>
								<Field
									className={'w-12/25'}
									component={SwitchField}
									label={t('loc:Platba kartou')}
									name={'payByCard'}
									size={'middle'}
									disabled={disabledForm}
									required
								/>
							</Row>
							<Field
								component={InputField}
								label={t('loc:Iné spôsoby platby')}
								name={'otherPaymentMethods'}
								size={'large'}
								placeholder={t('loc:Aké spôsoby platby akceptujete, napr. hotovosť, poukazy, kryptomeny,...')}
								disabled={disabledForm}
								maxLength={VALIDATION_MAX_LENGTH.LENGTH_500}
								className={'pb-6'}
								toCheck={isNotinoUser && changes['salonData.otherPaymentMethods']?.changed}
							/>
							<Field
								className={'m-0 pb-0'}
								component={ImgUploadField}
								name={'pricelists'}
								label={t('loc:Cenníky')}
								maxFileSize={UPLOAD.SALON_PRICELISTS.MAX_FILE_SIZE}
								accept={UPLOAD.SALON_PRICELISTS.ACCEPT}
								labelDescription={getUploadLimitsMessage(UPLOAD.SALON_PRICELISTS.ACCEPT, UPLOAD.SALON_PRICELISTS.MAX_FILE_SIZE)}
								signUrl={URL_UPLOAD_FILE}
								multiple
								maxCount={UPLOAD.SALON_PRICELISTS.MAX_COUNT}
								category={UPLOAD_IMG_CATEGORIES.SALON_PRICELIST}
								disabled={disabledForm}
								toCheck={
									isNotinoUser
										? {
												changed: changes['salonData->pricelists.id']?.changed,
												addedFilesIDs: getValueToCheckUploadField(changes['salonData->pricelists.id'])
											}
										: undefined
								}
							/>
							{voucherModalControlButtons}
						</Col>
					</Row>
				</FormSection>
				<FormSection name={SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION}>
					<Row>
						<Col span={24}>
							<h3 className={'mb-0 flex items-center'}>
								<SocialIcon className={'text-notino-black mr-2'} />
								{t('loc:Sociálne siete')}
							</h3>
							<Divider className={'mb-3 mt-3'} />
							<Field
								component={InputField}
								label={t('loc:Webstránka')}
								name={'socialLinkWebPage'}
								size={'large'}
								prefix={(<GlobeIcon />) as any}
								placeholder={t('loc:Odkaz na webovú stránku salóna')}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.socialLinkWebPage']?.changed}
							/>
							<Field
								component={InputField}
								label={t('loc:Facebook')}
								name={'socialLinkFB'}
								size={'large'}
								prefix={(<FacebookIcon />) as any}
								placeholder={t('loc:Odkaz na Facebook')}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.socialLinkFB']?.changed}
							/>
							<Field
								component={InputField}
								label={t('loc:Instagram')}
								name={'socialLinkInstagram'}
								size={'large'}
								prefix={(<InstagramIcon />) as any}
								placeholder={t('loc:Odkaz na Instagram')}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.socialLinkInstagram']?.changed}
							/>
							<Field
								component={InputField}
								label={t('loc:Pinterest')}
								name={'socialLinkPinterest'}
								size={'large'}
								prefix={(<SocialPinterest />) as any}
								placeholder={t('loc:Odkaz na Pinterest')}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.socialLinkPinterest']?.changed}
							/>
							<Field
								component={InputField}
								label={t('loc:Youtube')}
								name={'socialLinkYoutube'}
								size={'large'}
								prefix={(<SocialYoutube />) as any}
								placeholder={t('loc:Odkaz na Youtube')}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.socialLinkYoutube']?.changed}
							/>
							<Field
								component={InputField}
								label={t('loc:TikTok')}
								name={'socialLinkTikTok'}
								size={'large'}
								prefix={(<SocialTikTok />) as any}
								placeholder={t('loc:Odkaz na TikTok')}
								disabled={disabledForm}
								toCheck={isNotinoUser && changes['salonData.socialLinkTikTok']?.changed}
							/>
						</Col>
					</Row>
				</FormSection>
			</Space>
		</Form>
	)
}

const form = reduxForm<ISalonForm, ComponentProps>({
	form: FORM.SALON,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	destroyOnUnmount: true,
	onSubmitFail: showErrorNotification,
	validate: validationSalonFn
})(withPromptUnsavedChanges(SalonForm))

export default form
