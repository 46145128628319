import React from 'react'
import { flatten, isEmpty, map } from 'lodash'
import { Tag, Tooltip, Image } from 'antd'
import i18next, { TFunction } from 'i18next'
import { NavigateFunction } from 'react-router'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { ColumnsType } from 'antd/es/table'

// types
import { AutocompleteLabelInValue, GetUrls, ISalonForm, OpeningHours, RequestResponse } from '../../../types/interfaces'
import { RootState } from '../../../reducers'
import { ISelectedSalonPayload } from '../../../reducers/selectedSalon/selectedSalonActions'
import { IBasicSalon } from '../../../reducers/salons/salonsActions'
import { ICategoriesPayload } from '../../../reducers/categories/categoriesActions'
import { StringArrayValueToCheck } from '../../../types/schemaTypes'

// enums
import { SALON_STATES, SALON_CREATE_TYPE, SALON_SOURCE_TYPE, SALON_FILTER_STATES, SALON_DETAIL_FORM_SECTION } from '../../../utils/enums'

// components
import {
	checkSameOpeningHours,
	checkWeekend,
	createSameOpeningHours,
	initOpeningHours,
	mapRawOpeningHoursToComponentOpeningHours,
	orderDaysInWeek
} from '../../../components/OpeningHours/OpeningHoursUtils'

// assets
import CheckerIcon from '../../../assets/icons/check-icon-circle-icon.svg?react'
import CrossIcon from '../../../assets/icons/close-circle-icon.svg?react'
import CalendarIcon from '../../../assets/icons/calendar-icon.svg?react'

// utils
import { getAssignedUserLabel, setOrder } from '../../../utils/helper'
import { LocalizedDateTime } from '../../../utils/intl'

const getPhoneDefaultValue = (phonePrefixCountryCode: string) => [
	{
		phonePrefixCountryCode,
		phone: null
	}
]

export type SalonInitType = ISelectedSalonPayload['data'] & IBasicSalon

/**
 *
 * @param salonData
 * @param phonePrefixCountryCode
 * @param salonNameFromSelect
 * @returns
 */
export const initSalonFormData = (salonData: SalonInitType | null, phonePrefixCountryCode: string, salonNameFromSelect = false) => {
	// stacilo by isEmpty ale aby typescript nehucal tak je aj prva podmienka
	if (!salonData || isEmpty(salonData)) {
		return {}
	}
	// init data for existing salon
	const mappedOpeningHours = mapRawOpeningHoursToComponentOpeningHours(salonData.openingHours)
	const openOverWeekend: boolean = checkWeekend(mappedOpeningHours)
	const sameOpenHoursOverWeek: boolean = checkSameOpeningHours(mappedOpeningHours)
	const openingHours: OpeningHours = initOpeningHours(mappedOpeningHours, sameOpenHoursOverWeek, openOverWeekend)?.sort(orderDaysInWeek) as OpeningHours
	// pre sprave zobrazenie informacnych hlasok a disabled stavov submit buttonov je potrebne dat pozor, aby isPristine fungovalo spravne = teda pri pridavani noveho fieldu je to potrebne vzdy skontrolovat
	// napr. ak pride z BE aboutUsFirst: undefined, potom prepisem hodnotu vo formulari a opat ju vymazem, tak do reduxu sa ta prazdna hodnota uz neulozi ako undefined ale ako null
	// preto maju vsetky inicializacne hodnoty, pre textFieldy a textAreaFieldy fallback || null (pozri implementaciu tychto komponentov, preco sa to tam takto uklada)
	const initialData: ISalonForm = {
		[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION]: {
			salonNameFromSelect,
			name: salonNameFromSelect
				? {
						key: salonData.id,
						label: salonData.name,
						value: salonData.id
					}
				: salonData.name,
			aboutUsFirst: salonData.aboutUsFirst || null,
			logo: salonData.logo?.id
				? [
						{
							uid: salonData.logo.id,
							url: salonData.logo?.original,
							thumbUrl: salonData.logo?.resizedImages?.thumbnail
						}
					]
				: [],
			gallery: map(salonData.images, (image) => ({
				thumbUrl: image?.resizedImages?.thumbnail || image?.original,
				url: image?.original,
				uid: image?.id,
				isCover: image?.isCover
			})),
			cosmeticIDs: salonData.cosmetics?.reduce((acc, cosmetic) => {
				if (cosmetic) {
					return [...acc, cosmetic.id]
				}
				return acc
			}, [] as string[]),
			languageIDs: salonData.languages?.reduce((acc, lng) => {
				if (lng) {
					return [...acc, lng.id]
				}
				return acc
			}, [] as string[])
		},
		[SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION]: {
			email: salonData.email,
			phones:
				salonData.phones && !isEmpty(salonData.phones)
					? salonData.phones.map((phone) => ({
							id: phone.id,
							phonePrefixCountryCode: phone.phonePrefixCountryCode || null,
							phone: phone.phone || null
						}))
					: getPhoneDefaultValue(phonePrefixCountryCode)
		},
		[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]: {
			address: null,
			latitude: salonData.address?.latitude ?? null,
			longitude: salonData.address?.longitude ?? null,
			city: salonData.address?.city || null,
			street: salonData.address?.street || null,
			zipCode: salonData.address?.zipCode || null,
			country: salonData.address?.countryCode || null,
			streetNumber: salonData.address?.streetNumber || null,
			locationNote: salonData.locationNote || null,
			parkingNote: salonData.parkingNote || null
		},
		[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]: {
			openingHours,
			sameOpenHoursOverWeek,
			openOverWeekend
		},
		[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION]: {
			payByCard: !!salonData.payByCard,
			payByCash: !!salonData?.payByCash,
			otherPaymentMethods: salonData.otherPaymentMethods || null,
			pricelists: map(salonData.pricelists, (file) => ({ url: file?.original, uid: file?.id, name: file?.fileName }))
		},
		[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION]: {
			socialLinkWebPage: salonData.socialLinkWebPage || null,
			socialLinkFB: salonData.socialLinkFB || null,
			socialLinkInstagram: salonData.socialLinkInstagram || null,
			socialLinkYoutube: salonData.socialLinkYoutube || null,
			socialLinkTikTok: salonData.socialLinkTikTok || null,
			socialLinkPinterest: salonData.socialLinkPinterest || null
		},
		id: salonData.id || null,
		state: salonData.state as SALON_STATES,
		// categoryIDs for basic salon
		categoryIDs: (!isEmpty(salonData?.categories) ? salonData?.categories.map((category) => category.id) : null) as ISalonForm['categoryIDs']
	}

	return initialData
}

export const initEmptySalonFormData = (phonePrefixCountryCode: string, salonNameFromSelect = false): ISalonForm => {
	const initData: ISalonForm = {
		[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION]: {
			salonNameFromSelect,
			logo: [],
			gallery: []
		},
		[SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION]: {
			phones: getPhoneDefaultValue(phonePrefixCountryCode)
		},
		[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION]: {},
		[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION]: {
			openingHours: initOpeningHours(undefined, true, false),
			openOverWeekend: false,
			sameOpenHoursOverWeek: true
		},
		[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION]: {
			payByCard: false,
			payByCash: true
		},
		[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION]: {},
		id: null,
		categoryIDs: null
	}

	return initData
}

export const getSalonDataForSubmission = (data: ISalonForm) => {
	const openingHours: OpeningHours = createSameOpeningHours(
		data[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION].openingHours,
		data[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION].sameOpenHoursOverWeek,
		data[SALON_DETAIL_FORM_SECTION.OPENING_HOURS_SECTION].openOverWeekend
	)?.sort(orderDaysInWeek) as OpeningHours
	const phones = data[SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION].phones?.reduce(
		(acc, phone) => {
			if (phone.phone && phone.phonePrefixCountryCode) {
				return [
					...acc,
					{
						phone: phone.phone,
						phonePrefixCountryCode: phone.phonePrefixCountryCode
					}
				]
			}
			return acc
		},
		[] as { phone: string; phonePrefixCountryCode: string }[]
	)

	return {
		// basicInformation section
		name: data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].salonNameFromSelect
			? (data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].name as AutocompleteLabelInValue)?.label
			: data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].name,
		aboutUsFirst: data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].aboutUsFirst,
		cosmeticIDs: data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].cosmeticIDs,
		languageIDs: data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].languageIDs,
		logoID: map(data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].logo, (image) => image?.id ?? image?.uid)[0] ?? null,
		imageIDs: (data[SALON_DETAIL_FORM_SECTION.BASIC_INFO_SECTION].gallery || []).map((image) => ({
			id: image?.id ?? image?.uid,
			isCover: image?.isCover ?? false
		})),
		// contactInformation section
		phones,
		email: data[SALON_DETAIL_FORM_SECTION.CONTACT_INFO_SECTION].email,
		// address section
		city: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].city,
		countryCode: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].country,
		latitude: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].latitude,
		longitude: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].longitude,
		street: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].street,
		streetNumber: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].streetNumber,
		zipCode: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].zipCode,
		locationNote: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].locationNote,
		parkingNote: data[SALON_DETAIL_FORM_SECTION.ADDRESS_SECTION].parkingNote,
		// opening hours section
		openingHours: openingHours || [],
		// payment options section
		payByCard: !!data[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION].payByCard,
		payByCash: !!data[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION].payByCash,
		otherPaymentMethods: data[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION].otherPaymentMethods,
		pricelistIDs: (data[SALON_DETAIL_FORM_SECTION.PAYMENT_OPTIONS_SECTION].pricelists || []).map((image) => image?.id ?? image?.uid),
		// social network section
		socialLinkFB: data[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION].socialLinkFB,
		socialLinkInstagram: data[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION].socialLinkInstagram,
		socialLinkWebPage: data[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION].socialLinkWebPage,
		socialLinkTikTok: data[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION].socialLinkTikTok,
		socialLinkYoutube: data[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION].socialLinkYoutube,
		socialLinkPinterest: data[SALON_DETAIL_FORM_SECTION.SOCIAL_NETWORKS_SECTION].socialLinkPinterest
	}
}

// salon status tags
export const getSalonTagPublished = (salonStatus?: SALON_STATES) => {
	if (!salonStatus) {
		return null
	}

	switch (salonStatus) {
		case SALON_STATES.PUBLISHED:
		case SALON_STATES.PUBLISHED_PENDING:
		case SALON_STATES.PUBLISHED_DECLINED:
			return (
				<Tag className={'noti-tag bg-status-published'}>
					<span>{i18next.t('loc:Publikovaný')}</span>
				</Tag>
			)
		default:
			return (
				<Tag className={'noti-tag bg-status-notPublished'}>
					<span>{i18next.t('loc:Nepublikovaný')}</span>
				</Tag>
			)
	}
}

export const getSalonTagDeleted = (deleted?: boolean, returnOnlyDeleted = false) => {
	if (deleted) {
		return (
			<Tag className={'noti-tag danger'}>
				<span>{i18next.t('loc:Vymazaný')}</span>
			</Tag>
		)
	}

	if (returnOnlyDeleted) {
		return null
	}

	return (
		<Tag className={'noti-tag info'}>
			<span>{i18next.t('loc:Nevymazaný')}</span>
		</Tag>
	)
}

export const getSalonTagChanges = (salonStatus?: SALON_STATES) => {
	if (!salonStatus) {
		return null
	}

	switch (salonStatus) {
		case SALON_STATES.NOT_PUBLISHED_PENDING:
		case SALON_STATES.PUBLISHED_PENDING:
			return (
				<Tag className={'noti-tag bg-status-pending'}>
					<span>{i18next.t('loc:Na schválenie')}</span>
				</Tag>
			)
		case SALON_STATES.NOT_PUBLISHED_DECLINED:
		case SALON_STATES.PUBLISHED_DECLINED:
			return (
				<Tag className={'noti-tag bg-status-declined'}>
					<span>{i18next.t('loc:Zamietnuté')}</span>
				</Tag>
			)
		default:
			return null
	}
}

export const getSalonTagCreateType = (salonStatus?: SALON_STATES, createType?: SALON_CREATE_TYPE) => {
	if (salonStatus && createType) {
		if (createType === SALON_CREATE_TYPE.NON_BASIC) {
			return (
				<Tag className={'noti-tag bg-status-premium'}>
					<span>{i18next.t('loc:PREMIUM')}</span>
				</Tag>
			)
		}

		if (createType === SALON_CREATE_TYPE.BASIC) {
			return (
				<Tag className={'noti-tag bg-status-basic'}>
					<span>{i18next.t('loc:BASIC')}</span>
				</Tag>
			)
		}
	}
	return null
}

export const getSalonTagSourceType = (sourceType?: string | SALON_SOURCE_TYPE) => {
	switch (sourceType) {
		case SALON_SOURCE_TYPE.IMPORT:
			return (
				<Tag className={'noti-tag bg-source-import'}>
					<span>{i18next.t('loc:Import')}</span>
				</Tag>
			)

		case SALON_SOURCE_TYPE.NOTINO:
			return (
				<Tag className={'noti-tag bg-source-notino'}>
					<span>{i18next.t('loc:Notino')}</span>
				</Tag>
			)

		case SALON_SOURCE_TYPE.PARTNER:
			return (
				<Tag className={'noti-tag bg-source-partner'}>
					<span>{i18next.t('loc:Partner')}</span>
				</Tag>
			)

		default:
			return null
	}
}

export const getCheckerIcon = (valid?: boolean) =>
	valid ? <CheckerIcon className={'medium-icon text-notino-success'} /> : <CrossIcon className={'medium-icon text-notino-gray'} />

export type SalonsPageCommonProps = {
	selectedCountry?: string
	t: TFunction
	navigate: NavigateFunction
	dispatch: ThunkDispatch<RootState, any, Action>
}

type SalonColumnType = NonNullable<RequestResponse<GetUrls['/api/b2b/admin/salons/']>['salons']>[0] &
	NonNullable<RequestResponse<GetUrls['/api/b2b/admin/salons/to-check']>['salons']>[0]

/**
 * define common columns used in salons pages / active / deleted / to check /
 */
export const getSalonsColumns = (order?: string, categories?: ICategoriesPayload['data']) => {
	const tableColumns: {
		[key: string]: (props?: ColumnsType<SalonColumnType>[0]) => ColumnsType<SalonColumnType>[0]
	} = {
		id: (columnProps) => ({
			title: i18next.t('loc:ID'),
			dataIndex: 'id',
			key: 'id',
			ellipsis: false,
			sorter: false,
			render: (_value, record) => {
				const value = record.id
				const firstThree = value.substring(0, 3)
				const lastThree = value.substring(value.length - 3)

				return <Tooltip title={value}>{`${firstThree}...${lastThree}`}</Tooltip>
			},
			...columnProps
		}),
		name: (columnProps) => ({
			title: <span id={'sortby-title'}>{i18next.t('loc:Názov')}</span>,
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(order, 'name'),
			render: (_value, record) => record.name || '-',
			...columnProps
		}),
		address: (columnProps) => ({
			title: i18next.t('loc:Adresa'),
			dataIndex: 'address',
			key: 'address',
			ellipsis: true,
			sorter: false,
			render: (_value, record) => {
				const value = record.address
				return !isEmpty(value) ? <>{value?.city && value?.street ? `${value?.city}, ${value?.street}` : ''}</> : '-'
			},
			...columnProps
		}),
		createType: (columnProps) => ({
			title: i18next.t('loc:Typ salónu'),
			dataIndex: 'createType',
			key: 'createType',
			sorter: false,
			render: (_value, record) => getSalonTagCreateType(record.state as SALON_STATES, record.createType as SALON_CREATE_TYPE),
			...columnProps
		}),
		createdAt: (columnProps) => ({
			title: i18next.t('loc:Vytvorený'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(order, 'createdAt'),
			render: (value) => <LocalizedDateTime date={value} fallback={'-'} ellipsis />,
			...columnProps
		}),
		lastUpdatedAt: (columnProps) => ({
			title: i18next.t('loc:Upravený'),
			dataIndex: 'lastUpdatedAt',
			key: 'lastUpdatedAt',
			sorter: false,
			render: (value) => <LocalizedDateTime date={value} fallback={'-'} ellipsis />,
			...columnProps
		}),
		deletedAt: (columnProps) => ({
			title: i18next.t('loc:Vymazaný'),
			dataIndex: 'deletedAt',
			key: 'deletedAt',
			sorter: false,
			render: (value) => <LocalizedDateTime date={value} fallback={'-'} ellipsis />,
			...columnProps
		}),
		isPublished: (columnProps) => ({
			title: i18next.t('loc:Publikovaný'),
			key: 'isPublished',
			ellipsis: true,
			sorter: false,
			render: (_value, record) => {
				let checked = false
				switch (record.state) {
					case SALON_STATES.PUBLISHED:
					case SALON_STATES.PUBLISHED_PENDING:
					case SALON_STATES.PUBLISHED_DECLINED:
						checked = true
						break
					default:
						break
				}
				return <div className={'flex items-center'}>{getCheckerIcon(checked)}</div>
			},
			...columnProps
		}),
		changes: (columnProps) => ({
			title: i18next.t('loc:Zmeny'),
			key: 'changes',
			ellipsis: true,
			sorter: false,
			render: (_value, record) => getSalonTagChanges(record.state as SALON_STATES),
			...columnProps
		}),
		fillingProgress: (columnProps) => ({
			title: i18next.t('loc:Vyplnenie profilu'),
			dataIndex: 'fillingProgressSalon',
			key: 'fillingProgress',
			sorter: true,
			sortDirections: ['ascend', 'descend', 'ascend'],
			sortOrder: setOrder(order, 'fillingProgress'),
			render: (_value: number | undefined, record) => {
				const value = record.fillingProgressSalon
				return <span className={'w-9 flex-shrink-0'}>{value ? `${value}%` : ''}</span>
			},
			...columnProps
		}),
		assignedUser: (columnProps) => ({
			title: i18next.t('loc:Notino používateľ'),
			dataIndex: 'assignedUser',
			key: 'assignedUser',
			sorter: false,
			render: (_value, record) => {
				const value = record.assignedUser
				return <span className={'inline-block truncate w-full'}>{getAssignedUserLabel(value)}</span>
			},
			...columnProps
		}),
		premiumSourceUserType: (columnProps) => ({
			title: i18next.t('loc:Zdroj PREMIUM'),
			dataIndex: 'premiumSourceUserType',
			key: 'premiumSourceUserType',
			sorter: false,
			render: (_value, record) => {
				const value = record.premiumSourceUserType
				return getSalonTagSourceType(value)
			},
			...columnProps
		}),
		enabledRS: (columnProps) => ({
			title: i18next.t('loc:Rezervačný systém'),
			dataIndex: 'settings',
			key: 'settings',
			sorter: false,
			render: (_value, record) => {
				const value = record.settings
				return getCheckerIcon(value?.enabledReservations)
			},
			...columnProps
		}),
		availableReservationSystem: (columnProps) => ({
			title: i18next.t('loc:Dostupné pre online rezervácie'),
			dataIndex: 'availableReservationSystem',
			key: 'availableReservationSystem',
			sorter: false,
			render: (_value, record) => {
				const { daysWithAvailableReservationSlotCount, availableReservationSystem } = record
				if (availableReservationSystem && daysWithAvailableReservationSlotCount) {
					return (
						<div className={'flex items-center h-gull gap-1'}>
							<CalendarIcon className={'flex-shrink-0'} /> {daysWithAvailableReservationSlotCount > 365 ? `365+` : daysWithAvailableReservationSlotCount}
						</div>
					)
				}
				return getCheckerIcon(availableReservationSystem)
			},
			...columnProps
		}),
		upcomingReservationsCount: (columnProps) => ({
			title: i18next.t('loc:Rezervácie v budúcnosti'),
			dataIndex: 'upcomingReservationsCount',
			key: 'upcomingReservationsCount',
			sorter: false,
			...columnProps
		}),
		categories: (columnProps) => {
			const fallback = '-'

			const categoriesCol = {
				title: i18next.t('loc:Odvetvia'),
				dataIndex: 'categories',
				key: 'categories',
				sorter: false,
				render: () => fallback,
				...columnProps
			}

			if (categories) {
				const industries = categories.reduce<{ [key: string]: { image?: string; name: string } }>(
					(result, industry) => ({ ...result, [industry.id]: { image: industry.image?.resizedImages?.thumbnail, name: industry.name || industry.id } }),
					{}
				)

				return {
					...categoriesCol,
					render: (_value, record) => {
						const value = record.categories
						if (value?.length > 0) {
							const industriesContent = value.reduce<JSX.Element[]>((acc, category) => {
								const industry = industries[category.id]
								if (!industry) {
									// eslint-disable-next-line no-console
									console.error('Missing industry with ID: ', category.id)
									return acc
								}

								return [
									...acc,
									<Tooltip key={category.id} title={industry.name}>
										<Image src={industry.image} loading='lazy' width={32} height={32} className='pr-0-5 pb-0-5 rounded' alt={industry.name} preview={false} />
									</Tooltip>
								]
							}, [])

							// NOTE: z nejakeho neznameho dovodu sa tu obcas dostal object miesto pola a potom padla aplikacia, tak pre istotu tento check
							return <div className='flex flex-wrap'>{Array.isArray(industriesContent) ? industriesContent : fallback}</div>
						}

						return fallback
					}
				}
			}

			return categoriesCol
		}
	}
	return tableColumns
}

export const getCategoryThirdLevelIDsOptions = (categories: ICategoriesPayload['data']) =>
	flatten(
		map(categories, (industry) =>
			map(industry.children, (category) => {
				return {
					label: category.name || category.id,
					key: category.id,
					children: map(category.children, (item) => {
						return {
							value: item.id,
							label: item.name || item.id,
							key: item.id,
							extra: {
								image: industry.image?.resizedImages.thumbnail || industry.image?.original
							}
						}
					})
				}
			})
		)
	)

export const publishedSalonOptions = () => [
	{ label: i18next.t('loc:Publikovaný'), value: SALON_FILTER_STATES.PUBLISHED, key: SALON_FILTER_STATES.PUBLISHED, tagClassName: 'bg-status-published' },
	{ label: i18next.t('loc:Nepublikovaný'), value: SALON_FILTER_STATES.NOT_PUBLISHED, key: SALON_FILTER_STATES.NOT_PUBLISHED, tagClassName: 'bg-status-notPublished' }
]

export const salonChangesOptions = () => [
	{
		label: i18next.t('loc:Na schválenie'),
		value: SALON_FILTER_STATES.PENDING_PUBLICATION,
		key: SALON_FILTER_STATES.PENDING_PUBLICATION,
		tagClassName: 'bg-status-pending'
	},
	{ label: i18next.t('loc:Zamietnuté'), value: SALON_FILTER_STATES.DECLINED, key: SALON_FILTER_STATES.DECLINED, tagClassName: 'bg-status-declined' }
]

export const salonCreateTypesOptions = () => [
	{ label: i18next.t('loc:BASIC'), value: SALON_CREATE_TYPE.BASIC, key: SALON_CREATE_TYPE.BASIC, tagClassName: 'bg-status-basic' },
	{ label: i18next.t('loc:PREMIUM'), value: SALON_CREATE_TYPE.NON_BASIC, key: SALON_CREATE_TYPE.NON_BASIC, tagClassName: 'bg-status-premium' }
]

export const getValueToCheckUploadField = (value?: StringArrayValueToCheck) => {
	if (value?.changed && value.new) {
		// get only new values different from old ones
		return value.new.filter((newID) => !value.old?.includes(newID))
	}

	return []
}
