import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reset, initialize } from 'redux-form'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import i18next from 'i18next'

// components
import RegistrationForm from './components/RegistrationForm'
import SelectAccountModal from '../../components/SelectAccountModal'

// actions
import { getCurrentUser, processAuthorizationResult } from '../../reducers/users/userActions'

// utils
import { postReq } from '../../utils/request'
import { FORM, ASSET_TYPE, MSG_TYPE } from '../../utils/enums'
import { getAssignedUserLabel, getPrefixCountryCode, showNotifications } from '../../utils/helper'
import { gtag } from '../../utils/dataLayer'
import { getAccessToken, getRefreshToken, isLoggedIn } from '../../utils/auth'
import { DATA_LAYER_EVENTS } from '../../utils/dataLayerEnums'

// types
import { IAuthUserPayload, IRegistrationForm } from '../../types/interfaces'

// reducers
import { RootState } from '../../reducers'
import { getDocuments } from '../../reducers/documents/documentActions'

type Props = {}

const RegistrationPage: FC<Props> = () => {
	const dispatch = useDispatch()
	const [t] = useTranslation()
	const { search } = useLocation()

	const { email } = qs.parse(search, { ignoreQueryPrefix: true })

	const optionsPhonePrefixesAll = useSelector((state: RootState) => state.config.config.optionsPhonePrefixesAll)
	const documents = useSelector((state: RootState) => state.documents.documents)

	const [selectAccountModalData, setSelectAccountModalData] = useState<{ user: IAuthUserPayload['data']; values: IRegistrationForm } | null>(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		const phonePrefixCountryCode = getPrefixCountryCode(optionsPhonePrefixesAll)

		const initData = {
			phonePrefixCountryCode,
			email
		}
		dispatch(initialize(FORM.REGISTRATION, initData || {}))
	}, [optionsPhonePrefixesAll, dispatch, email])

	useEffect(() => {
		dispatch(getDocuments({ page: 1, limit: 2, assetTypes: [ASSET_TYPE.B2B_APP_TERMS_CONDITIONS, ASSET_TYPE.B2B_PRIVACY_POLICY], languageCode: i18next.language }))
	}, [dispatch])

	const handleRegistrationRequest = async (values: IRegistrationForm, postMessage = false) => {
		setSubmitting(true)
		try {
			const reqBody = {
				email: values.email,
				password: values.password,
				phonePrefixCountryCode: values.phonePrefixCountryCode,
				phone: values.phone,
				agreeGDPR: values.agreeGDPR,
				agreeMarketing: !!values?.marketing
			}

			const { data } = await postReq('/api/b2b/admin/users/registration', { params: {}, reqBody, customConfig: { skip401Logout: true } })
			gtag('event', DATA_LAYER_EVENTS.ACCOUNT_SIGN_UP)
			dispatch(reset(FORM.REGISTRATION))
			dispatch(processAuthorizationResult(data, t('paths:activation'), postMessage))
		} catch (e) {
			// eslint-disable-next-line no-console
			console.log(e)
		} finally {
			setSubmitting(false)
		}
	}

	const handleRegistrationSubmit = async (values: IRegistrationForm) => {
		if (isLoggedIn()) {
			setSubmitting(true)
			try {
				const { data: user } = await dispatch(getCurrentUser())
				setSelectAccountModalData({ user, values })
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e)
				handleRegistrationRequest(values, true)
			} finally {
				setSubmitting(false)
			}
			return
		}

		handleRegistrationRequest(values)
	}

	const handleContinueWithCurrentAccount = async (user: IAuthUserPayload['data']) => {
		const accessToken = getAccessToken()
		const refreshToken = getRefreshToken()

		if (accessToken && refreshToken && user) {
			await dispatch(processAuthorizationResult({ accessToken, refreshToken, user }))
		} else {
			showNotifications([{ type: MSG_TYPE.ERROR, message: t('loc:Ups niečo sa pokazilo') }])
		}
	}

	const handleLoginWithNewAccount = async (values: IRegistrationForm) => {
		handleRegistrationRequest(values, true)
	}

	return (
		<>
			<RegistrationForm onSubmit={handleRegistrationSubmit} documents={documents} isSubmitting={submitting} />
			{selectAccountModalData && (
				<SelectAccountModal
					open
					onCancel={() => setSelectAccountModalData(null)}
					currentAccountName={selectAccountModalData.user ? getAssignedUserLabel(selectAccountModalData.user, true) : t('loc:iným')}
					handleContinueWithCurrentAccount={() => handleContinueWithCurrentAccount(selectAccountModalData.user)}
					handleLoginWithNewAccount={() => handleLoginWithNewAccount(selectAccountModalData.values)}
					submitting={submitting}
					additionalText={
						<p className={'mb-4'}>
							<Trans
								defaults={t('loc:Chcete ďalej pokračovať pod týmto účtom alebo dokončiť registráciu s novým účtom <strong>{{user}}</strong>?')}
								components={{ strong: <strong className={'text-notino-black font-semibold'} /> }}
								values={{ user: selectAccountModalData.values.email }}
							/>
						</p>
					}
					primaryButtonText={t('loc:Registrovať sa s novým účtom')}
					secondaryButtonText={t('loc:Pokračovať pod prihláseným účtom')}
				/>
			)}
		</>
	)
}

export default RegistrationPage
