import React, { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { notification } from 'antd'
import { useDispatch } from 'react-redux'
import i18next from 'i18next'
import { NavigateFunction, useNavigate } from 'react-router'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'

// utils
import { getAssignedUserLabel } from '../utils/helper'
import { APP_BROADCAST_CHANNEL_NAME, BROADCAST_CHANNEL_TYPE, BroadcastLoginMessage, BroadcastLogoutMessage, BroadcastMessageEvent, appBroadcastChannel } from '../utils/broadcast'

// redux
import { RESET_STORE } from '../reducers/generalTypes'
// eslint-disable-next-line import/no-cycle
import { RootState } from '../reducers'

// assets
import CloseIcon from '../assets/icons/close-icon-modal.svg?react'

const LOGIN_NOTIFICATION_KEY = 'noti-login-notification'
export const LOGOUT_NOTIFICATION_KEY = 'noti-logout-notification'
const SESSION_STORAGE_KEY = 'login-notification-data'

const onLoginMessage = (payload: BroadcastLoginMessage['payload']) => {
	window.location.href = i18next.t('paths:index')
	try {
		window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(payload))
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e)
	}
}

const onLogoutMessage = (navigate: NavigateFunction, dispatch: ThunkDispatch<RootState, any, Action>, payload: BroadcastLogoutMessage['payload']) => {
	navigate(i18next.t('paths:login'))
	dispatch({ type: RESET_STORE })
	const { user } = payload

	notification.destroy(LOGIN_NOTIFICATION_KEY)
	notification.destroy(LOGOUT_NOTIFICATION_KEY)

	notification.info({
		key: LOGOUT_NOTIFICATION_KEY,
		style: {
			width: 500
		},
		closeIcon: <CloseIcon className={'text-notino-black'} />,
		placement: 'top',
		duration: 0,
		message: user ? (
			<Trans
				defaults={i18next.t('loc:Odhlásili ste sa z aplikácie Notino Partner z účtu <strong>{{ user }}</strong>.')}
				components={{ strong: <strong className={'text-notino-black font-semibold'} /> }}
				values={{ user: getAssignedUserLabel(user, true) }}
			/>
		) : (
			i18next.t('loc:Odhlásili ste sa z aplikácie Notino Partner.')
		)
	})
}

const onLanguageChange = () => {
	window.location.reload()
}

const useBroadcastChannelListeners = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	useEffect(() => {
		const onMessage = (event: BroadcastMessageEvent) => {
			if ((event.currentTarget as BroadcastChannel)?.name === APP_BROADCAST_CHANNEL_NAME) {
				const { type, payload } = event.data
				if (type === BROADCAST_CHANNEL_TYPE.LOGIN) {
					onLoginMessage(payload)
				}

				if (type === BROADCAST_CHANNEL_TYPE.LOGOUT) {
					onLogoutMessage(navigate, dispatch, payload)
				}

				if (type === BROADCAST_CHANNEL_TYPE.USER_LANGUAGE_CHANGE) {
					onLanguageChange()
				}
			}
		}

		appBroadcastChannel.addEventListener('message', onMessage)
		return () => appBroadcastChannel.removeEventListener('message', onMessage)
	}, [navigate, dispatch])

	useEffect(() => {
		try {
			const loginNotificationData = window.sessionStorage.getItem(SESSION_STORAGE_KEY)
			window.sessionStorage.setItem(SESSION_STORAGE_KEY, '')

			if (!loginNotificationData) {
				return
			}

			const payload: BroadcastLoginMessage['payload'] = JSON.parse(loginNotificationData)
			const { user } = payload

			notification.info({
				key: LOGIN_NOTIFICATION_KEY,
				style: {
					width: 500
				},
				closeIcon: <CloseIcon className={'text-notino-black'} />,
				placement: 'top',
				duration: 0,
				message: user ? (
					<Trans
						defaults={i18next.t('loc:Boli ste prihlásený do aplikácie pod účtom <strong>{{ user }}</strong>.')}
						components={{ strong: <strong className={'text-notino-black font-semibold'} /> }}
						values={{ user: getAssignedUserLabel(user, true) }}
					/>
				) : (
					i18next.t('loc:Boli ste prihlásený do aplikácie.')
				)
			})
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e)
		}
	}, [])
}

export default useBroadcastChannelListeners
