import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'

// utils
import { STRINGS } from '../../../../utils/enums'

// assets
import EditIcon from '../../../../assets/icons/edit-icon.svg?react'

// validation
import DeleteButton from '../../../../components/DeleteButton'

type Props = {
	disabled?: boolean
	loading?: boolean
	title: string
	voucherCode: string | null
	onEditVoucherCode: () => void
	onDeleteVoucherCode: () => void
	onAddVoucherCode: () => void
	hasPermissionToEdit?: boolean
}

const AssignVoucher = (props: Props) => {
	const [t] = useTranslation()
	const { voucherCode, disabled, loading, title, onEditVoucherCode, onAddVoucherCode, onDeleteVoucherCode, hasPermissionToEdit } = props

	return (
		<div className={'bg-notino-grayLighter px-6 py-4 rounded-lg h-[fit-content]'}>
			<h5 className={'text-base font-bold text-black block mb-0'}>{title}</h5>
			<div className={'flex flex-col gap-4'}>
				{voucherCode ? (
					<p className={'text-sm text-notino-black italic mb-0 block'}>{voucherCode}</p>
				) : (
					<p className={'text-sm text-notino-grayDark mb-0 block'}>{t('loc:Nemáte vyplnený kód kupónu.')}</p>
				)}
				{(() => {
					if (!hasPermissionToEdit) {
						return null
					}

					if (voucherCode) {
						return (
							<div className={'flex gap-3'}>
								<Button className='noti-btn h-8' size='small' type='primary' icon={<EditIcon />} disabled={disabled} loading={loading} onClick={onEditVoucherCode}>
									{STRINGS(t).edit('').trim()}
								</Button>
								<DeleteButton className={'h-8'} size='small' onConfirm={onDeleteVoucherCode} entityName={''} disabled={disabled} />
							</div>
						)
					}
					return (
						<Button className='noti-btn h-8' size='small' type='primary' disabled={disabled} loading={loading} onClick={onAddVoucherCode}>
							{STRINGS(t).addRecord(t('loc:kód kupónu'))}
						</Button>
					)
				})()}
			</div>
		</div>
	)
}

export default AssignVoucher
