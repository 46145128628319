import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Col, Divider, Row, Spin } from 'antd'
import { initialize } from 'redux-form'
import i18next from 'i18next'

// reducers
import { RootState } from '../../reducers'

// assets
import SettingsIcon from '../../assets/icons/setting-icon.svg?react'

// components
import AccountSettingsForm from './components/AccountSettingsForm'

// utils
import { FORM } from '../../utils/enums'
import { handleLanguageChange } from '../../components/LanguagePicker'

// types
import { IAccountSettingsForm } from '../../types/interfaces'

const AccountSettingsPage = () => {
	const [t] = useTranslation()
	const dispatch = useDispatch()
	const currentUser = useSelector((state: RootState) => state.user.authUser)

	const [isSubmitting, setIsSubmitting] = useState(false)

	const handleSubmitUserLanguage = async (values: IAccountSettingsForm) => {
		setIsSubmitting(true)
		await handleLanguageChange(values.languageCode, currentUser.data?.id)
		setIsSubmitting(false)
	}

	useEffect(() => {
		dispatch(initialize(FORM.ACCOUNT_SETTINGS, { languageCode: currentUser.data?.language || i18next.language }))
	}, [currentUser.data?.language, dispatch])

	return (
		<>
			<div className='content-body small'>
				<Spin spinning={isSubmitting || currentUser.isLoading}>
					<Col className={'flex'}>
						<Row className={'w-full mx-9 h-full block'} justify='center'>
							<h3 className={'mb-0 mt-3 flex items-center'}>
								<SettingsIcon className={'text-notino-black mr-2 medium-icon'} /> {t('loc:Nastavenia')}
							</h3>
							<Divider className={'mb-3 mt-3'} />
							<AccountSettingsForm onSubmit={handleSubmitUserLanguage} />
						</Row>
					</Col>
				</Spin>
			</div>
		</>
	)
}

export default AccountSettingsPage
